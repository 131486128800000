import { Dexie, Table } from 'dexie';

export interface LocalCopyright {
  id?: number,
  title: string,
  description: string | null,
  categories: string | null,
  data: string,
  root_hash: string,
  author_wallet_address: string,
  royalty_wallet_address?: string
}

export class AppDB extends Dexie {
    copyrights!: Table<LocalCopyright, number>;
    
    constructor() {
        super('autoproof');
    
        this.version(3).stores({ copyrights: '++id, author_wallet_address' });
      }
}

export const db = new AppDB();
