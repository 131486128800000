import {Component, OnInit, signal} from '@angular/core';
import {Router, RouterLink, RouterOutlet} from "@angular/router";
import {TuiButtonClose, TuiTab, TuiTabs} from "@taiga-ui/kit";
import {TelegramService} from "../../services/telegram.service";
import {StoreService} from "../../services/store.service";
import {ProfileHeaderComponent} from "./components/profile-header/profile-header.component";
import {TuiIslandDirective} from "@taiga-ui/legacy";
import {TuiButton} from "@taiga-ui/core";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    TuiTabs,
    TuiTab,
    ProfileHeaderComponent,
    TuiIslandDirective,
    TuiButton,
    TuiButtonClose,
  ],
})
export class DashboardComponent implements OnInit {
  
  activeTabIndex = 0;
  
  private readonly mainBannerId = 'mainBannerWasClosed';
  readonly bannerIsVisible = signal<boolean>(localStorage.getItem(this.mainBannerId) !== 'true');
  
  constructor(
    private router: Router,
    private storeService: StoreService,
    private telegramService: TelegramService,
  ) {}
  
  ngOnInit(): void {
    if (this.router.url.includes('/dashboard/settings')) {
      this.activeTabIndex = 2
    } else if (this.router.url.includes('/dashboard/referrals')) {
      this.activeTabIndex = 1
    }

    if (this.storeService.shouldShowSharedCopyright) {
      this.storeService.shouldShowSharedCopyright = false;
      const id = this.telegramService.startParam;
      this.router.navigate(['/copyrights', id])
    }
  }
  
  learnMore(): void {
    window.open('https://autoproof.dev/#what2do', '_blank');
  }
  
  closeBanner(): void {
    this.bannerIsVisible.set(false);
    localStorage.setItem(this.mainBannerId, 'true');
  }

}
