import { TuiProgress, TuiButtonLoading } from "@taiga-ui/kit";
import {Component, Inject, input, signal} from '@angular/core';
import {
  TuiAlertService,
  TuiDialogContext,
  TuiDialogService,
  TuiNotification,
  TuiButton,
  TuiLabel,
  TuiLink
} from "@taiga-ui/core";
import { PolymorpheusContent} from '@taiga-ui/polymorpheus';
import {TonConnectService} from "../../../../../../services/ton-connect.service";
import { Copyright } from '../../../../../../interfaces/copyright.interface';
import { Address } from '@ton/core';
import { CopyrightsStore } from '../../../../../../store/copyrights.store';
import { WebSocketService } from '../../../../../../services/web-socket.service';
import { BackendApiService } from '../../../../../../services/backend-api.service';
import { DatePipe } from '@angular/common';
import {PrettyCostPipe} from "../../../../../../pipes/pretty-cost";
import {NormalizeAddressPipe} from "../../../../../../pipes/normalize-address.pipe";
import {ContractAddressPipe} from "../../../../../../pipes/contract-address";

@Component({
  selector: 'app-client-view-confirm-materials',
  standalone: true,
  imports: [
    TuiButton,
    TuiLabel,
    TuiNotification,
    TuiProgress,
    TuiButton,
    TuiLabel,
    TuiNotification,
    TuiProgress,
    DatePipe,
    TuiButtonLoading,
    TuiLink,
    PrettyCostPipe,
    NormalizeAddressPipe,
    ContractAddressPipe
  ],
  templateUrl: './client-view-confirm-materials.component.html',
  styleUrl: './client-view-confirm-materials.component.scss'
})
export class ClientViewConfirmMaterialsComponent {
  
  copyright = input.required<Copyright>();
  isConfirming = signal<boolean>(false)
  readonly today = Date.now();

  constructor(
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    private alertService: TuiAlertService,
    private tonConnectService: TonConnectService,
    private copyrightsStore: CopyrightsStore,
    private webSocketService: WebSocketService,
    private backendService: BackendApiService
  ) {}

  async approve(): Promise<void> {
    this.isConfirming.set(true)
    
    const address = Address.normalize(this.copyright().address);

    try {
      this.backendService.mkAssignmentHash(address).subscribe(async hash => {
        await this.tonConnectService.approve(address, hash.hash)
      })
    } catch (error) {
      console.error(error);
      this.isConfirming.set(false);
      this.alertService.open('Could not send transaction to approve', {appearance: 'error'}).subscribe();
    }
  }

  async reject() : Promise<void> {
    this.isConfirming.set(true)

    const address = Address.normalize(this.copyright().address);

    try {
      await this.tonConnectService.cancel(address)
    } catch (error) {
      console.error(error);
      this.isConfirming.set(false);
      this.alertService.open('Could not send transaction to reject', {appearance: 'error'}).subscribe();
    }
  }

  showAcceptanceDialog(content: PolymorpheusContent<TuiDialogContext>) {
    this.dialogs.open(content).subscribe();
  }

}
