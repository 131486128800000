import { Address } from "@ton/core";
import { LocalCopyright } from "../db";
import {Copyright} from "../interfaces/copyright.interface";

export function isCopyrightTheSame(a: Copyright, b: LocalCopyright): boolean {
  return Address.normalize(a.author) === Address.normalize(b.author_wallet_address)
    && a.royalty?.toString() === b.royalty_wallet_address
    && a.document.tags === b.categories
    && a.document.title === b.title
    && a.document.description === b.description
    && a.document.rootHash === b.root_hash
    && a.document.data === b.data;
}
