import { MerkleTree } from "merkletreejs"
import {keccak256} from "js-sha3";

export async function createHash(files: File[]): Promise<[string, string[][]]> {
  const namesWithLeaves: string[][] = await Promise.all(
    files
      .map(f => f.arrayBuffer().then(buf => [f.name, keccak256.hex(buf)])),
  );

  const tree = new MerkleTree(namesWithLeaves.map(p => p[1]).sort(), keccak256.hex);
  const root = tree.getRoot().toString('hex');
  return [root, namesWithLeaves];
}
