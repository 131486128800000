import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CreteSnapshotResponse, SnapshotDto, SnapshotResult} from "../interfaces/snapshot.interface";
import {environment} from "../../environments/environment";
import {map, Observable} from "rxjs";
import {CreateUserDto, CreateUserResponse, IUser, IWallet} from "../interfaces/user.interface";
import { CopyrightAssignmentHash } from '../interfaces/copyright.interface';

@Injectable({
  providedIn: 'root'
})
export class BackendApiService extends HttpClient {

  getUser() {
    return this.get<IUser>(`${environment.backendApiUrl}/api/tg/users/me`).pipe(
      map((response: any) => response.user),
    );
  }

  createUser(createUserDto: CreateUserDto) {
    return this.post<CreateUserResponse>(`${environment.backendApiUrl}/api/tg/users`, createUserDto);
  }

  updateUser(updateUserDto: CreateUserDto) {
    return this.post<CreateUserResponse>(`${environment.backendApiUrl}/api/tg/users/profile`, updateUserDto);
  }

  getWallets() {
    return this.get<{ wallets: IWallet[] }>(`${environment.backendApiUrl}/api/v1/users/wallets`).pipe(
      map(response => response.wallets),
    );
  }

  addWalletToUser(address: string) {
    return this.post(`${environment.backendApiUrl}/api/v1/users/wallets`, { address });
  }

  uploadSnapshot(snapshot: SnapshotDto): Observable<CreteSnapshotResponse> {
    return this.post<CreteSnapshotResponse>(`${environment.backendApiUrl}/api/tg/upload_snap`, snapshot);
  }

  getSnapshots(): Observable<SnapshotResult[]> {
    return this.get<SnapshotResult[]>(`${environment.backendApiUrl}/api/tg/snapshots`).pipe(
      map((response: any) => response.snap_lst)
    );
  }

  appendTransactionIdToSnapshot(snapshotId: string, transactionId: string): Observable<any> {
    return this.post(`${environment.backendApiUrl}/api/tg/pay_snapshot/${snapshotId}`, {
      tg_transaction_id: transactionId,
    });
  }

  mkAssignmentHash(address: string): Observable<CopyrightAssignmentHash> {
    return this.post<CopyrightAssignmentHash>(`${environment.backendApiUrl}/api/v1/copyright/${address}/assignment`, {});
  }

  getTonPrice(): Observable<number> {
    const url = "https://api.binance.com/api/v3/ticker/price?symbol=TONUSDT"
    return this.get<number>(`${url}`, {}).pipe(map((response: any) => +response.price))
  }
}
