@if (copyright().price) {
  <tui-notification size="m" appearance="info"  class="tui-space_top-3"><div>
    <p class="tui-space_top-0">You can apply for the acquisition of exclusive rights.</p>
    <p>The act of application will be the freezing of the amount specified by the author.</p>
    <p>After receiving the original materials, you can accept or reject the rights.</p>
    <p>If accepted, the funds will be transferred to the freelancer, and you will receive exclusive rights.</p>
    <p class="tui-space_bottom-0">If rejected, all rights will remain with the author, and you will receive a refund of the amount.</p>
  </div></tui-notification>

  <div class="tui-space_top-3">
    <div class="tui-form__row">
      <tui-input-number [formControl]="amountControl" [disabled]="isClaiming()">
        Minimum amount of the claim in TON@if (priceInUsd()) { (~${{priceInUsd()}})}:
      </tui-input-number>
    </div>

  <tui-notification size="m" appearance="warning"  class="tui-space_top-3"><div>
      <p class="tui-space_top-0 tui-space_bottom-0">Minimum price includes <u>all commissions and fees</u> (~10% + 0.05 TON) for the claim.</p>  
  </div></tui-notification>

    <div class="tui-form__buttons">
      <button
        tuiButton
        type="button"
        appearance="primary"
        size="m"
        [disabled]="isClaiming() || amountControl.invalid"
        [loading]="isClaiming()"
        (click)="claimExclusiveRights()"
      >
        @if (tonConnectService.isWalletConnected$ | async) {
          Claim exclusive rights
        } @else {
          Connect wallet to claim exclusive rights
        }
      </button>

      <div class="align-center tui-space_top-5">
        <a tuiLink iconStart="@tui.circle-help" href="https://autoproof.dev/app-help" target="_blank">
          Need help? We're here to assist!
        </a>
      </div>
    </div>
  </div>
}
