import {TuiAccordion, TuiBadge, TuiChip, TuiLineClamp, TuiPulse, TuiStatus} from "@taiga-ui/kit";
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TuiButton, TuiHintDirective, TuiNotification} from "@taiga-ui/core";
import {NormalizeAddressPipe} from "../../../../../pipes/normalize-address.pipe";
import {CropAddressPipe} from "../../../../../pipes/crop-address.pipe";
import {TonConnectService} from "../../../../../services/ton-connect.service";
import {Address} from "@ton/core";
import {AsyncPipe} from "@angular/common";
import {map} from "rxjs";
import {TuiLet} from "@taiga-ui/cdk";
import {AuthService} from "../../../../../services/auth.service";

@Component({
  selector: 'app-manage-wallets',
  standalone: true,
  imports: [
    TuiAccordion,
    TuiButton,
    TuiNotification,
    TuiChip,
    NormalizeAddressPipe,
    CropAddressPipe,
    TuiLineClamp,
    TuiHintDirective,
    TuiBadge,
    TuiStatus,
    AsyncPipe,
    TuiLet,
    TuiPulse,
  ],
  templateUrl: './manage-wallets.component.html',
  styleUrl: './manage-wallets.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageWalletsComponent {

  readonly connectedWalletNormalizedAddress$ = this.tonConnectService.watchWallet().pipe(
    map(connectedWallet => connectedWallet?.account?.address ? Address.normalize(connectedWallet.account.address) : ''),
  );

  readonly isWalletConnected$ = this.tonConnectService.watchConnectionChange().pipe(
    map(() => this.tonConnectService.isWalletConnected()),
  );
  
  constructor(
    private tonConnectService: TonConnectService,
    public authService: AuthService,
  ) {}
  
  connectWallet(): void {
    this.tonConnectService.connectWallet();
  }

  disconnectWallet(): void {
    this.tonConnectService.disconnectWallet();
  }

}
