import {Component, DestroyRef, inject} from '@angular/core';
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {environment} from "../../../../environments/environment";
import {TonConnectService} from "../../../services/ton-connect.service";
import { TuiDialogService, TuiButton } from "@taiga-ui/core";
import { TuiBadge, TUI_CONFIRM, TuiConfirmData } from '@taiga-ui/kit';
import {filter, tap} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-referrals',
  standalone: true,
  imports: [
    CdkCopyToClipboard,
    TuiButton,
    TuiBadge
  ],
  templateUrl: './referrals.component.html',
  styleUrl: './referrals.component.scss'
})
export class ReferralsComponent {
  
  referralLink: string;

  constructor(
    private tonConnectService: TonConnectService,
    private dialogService: TuiDialogService,
    private destroyRef: DestroyRef,
    private authService: AuthService,
  ) {
    const appUrl = encodeURIComponent(`${environment.tgBotUrl}?start=referral=${this.authService.user()!.referral_code}`);
    const title = encodeURIComponent('Join new freelance platform in Telegram');
    this.referralLink = `https://t.me/share/url?url=${appUrl}&text=${title}`;
  }
  
  share(): void {
    if (this.tonConnectService.isWalletConnected()) {
      window.open(this.referralLink, '_blank');
      return;
    }
    
    const data: TuiConfirmData = {
      content: 'Please connect your wallet to which the bonuses will drop',
      yes: 'Connect wallet',
      no: 'Cancel',
    };
    
    this.dialogService.open<boolean>(TUI_CONFIRM, {
      label: 'Connect your wallet',
      size: 's',
      data,
    }).pipe(
      filter(Boolean),
      tap(() => this.tonConnectService.connectWallet()),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }
}
