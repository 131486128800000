import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@ton/core';

@Pipe({
  name: 'normalizeAddress',
  standalone: true
})
export class ContractAddressPipe implements PipeTransform {

  transform(address: Address): string | undefined {
    try {
      return Address.normalize(address).toString();
    } catch (error) {
      return undefined;
    }
  }

}
