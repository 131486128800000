@if (failed()) {
  Could not found a copyright
} @else if (copyright()) {
  @if (showProgress()) {
    <progress tuiProgressBar [max]="5" [segments]="5" [value]="progressStep()"></progress>
  }

  <app-copyright-card [copyright]="copyright()!" [(isPriceUpdating)]="isPriceUpdating"/>

  @if (isOwner()) {
    @switch (copyright()!.status) {
      @case ('stale') {
        <app-author-view-created [copyright]="copyright()!" [(isPriceUpdating)]="isPriceUpdating"/>
      }

      @case ('money_frozen') {
        <app-author-view-frozen-money [copyright]="copyright()!"/>
      }

      @case ('files_sent') {
        <app-author-view-awaiting-receival/>
      }

      @case ('viewed_by_client') {
        <app-author-view-awaiting-review [copyright]="copyright()!"/>
      }
    }
  } @else {
    <!-- client -->
    @switch (copyright()!.status) {
      @case ('stale') {
        <app-client-view-claiming [copyright]="copyright()!"/>
      }

      @case ('money_frozen') {
        <app-client-view-awaiting-materials/>
      }

      @case ('files_sent') {
        <app-client-view-confirm-review [copyright]="copyright()!"/>
      }

      @case ('viewed_by_client') {
        <app-client-view-confirm-materials [copyright]="copyright()!"/>
      }
    }
  }
} @else {
  <tui-loader [showLoader]="true"/>
}
