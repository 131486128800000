import { TuiButtonLoading } from "@taiga-ui/kit";
import { TuiInputNumberModule } from "@taiga-ui/legacy";
import {Component, computed, effect, input, signal} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TuiAlertService, TuiNotification, TuiButton, TuiLink} from "@taiga-ui/core";
import {Address, toNano} from "@ton/core";
import {TonConnectService} from "../../../../../../services/ton-connect.service";
import { Copyright } from '../../../../../../interfaces/copyright.interface';
import { PrettyCostPipe } from '../../../../../../pipes/pretty-cost';
import { BackendApiService } from '../../../../../../services/backend-api.service';
import {AuthService} from "../../../../../../services/auth.service";
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop";
import {AsyncPipe} from "@angular/common";
import {CopyrightsStore} from "../../../../../../store/copyrights.store";

@Component({
  selector: 'app-client-view-claiming',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TuiButton,
    TuiInputNumberModule,
    TuiNotification,
    TuiButtonLoading,
    AsyncPipe,
    TuiLink
  ],
  templateUrl: './client-view-claiming.component.html',
  styleUrl: './client-view-claiming.component.scss'
})
export class ClientViewClaimingComponent {
  
  readonly autoproofFeePercentage = 0.1;
  readonly tonTransactionFee = 0.05;
  readonly defaultAmount = 0.05;

  copyright = input.required<Copyright>()
  
  readonly tonPrice = signal<number | null>(null);
  readonly priceInUsd = signal<string | null>(null)
  readonly isClaiming = signal<boolean>(false);
  readonly priceAmount = computed(() => new PrettyCostPipe().transform(this.copyright().price));
  readonly claimAmount = computed(() => this.copyright().price
    ? (this.priceAmount() + (this.priceAmount() * this.autoproofFeePercentage) + this.tonTransactionFee)
    : this.defaultAmount,
  );

  readonly amountControl = new FormControl<number>(0, {
    nonNullable: true,
    validators: Validators.required,
  });
  readonly costControlValue = toSignal(this.amountControl.valueChanges);
  
  constructor(
    public tonConnectService: TonConnectService,
    private alertService: TuiAlertService,
    private authService: AuthService,
    private backendService: BackendApiService,
    private copyrightsStore: CopyrightsStore,
  ) {
    this.backendService.getTonPrice().pipe(
      takeUntilDestroyed(),
    ).subscribe(price => this.tonPrice.set(price));
    
    this.updatePriceInUsdOnChange();
    this.setDefaultValueAndValidationOfClaimAmount();
    this.stopClaimingLoaderOnClaimChange();
  }
  
  async claimExclusiveRights(): Promise<void> {
    if (this.amountControl.invalid) {
      return;
    }

    if (!this.tonConnectService.isWalletConnected()) {
      this.tonConnectService.connectWallet();
      return;
    }
    
    this.isClaiming.set(true)
    
    const address = Address.normalize(this.copyright().address);
    const claimAmount = toNano(this.amountControl.value);
    const currentUser = this.authService.user();
    
    try {
      await this.tonConnectService.claimRightsTransfer(address, currentUser!.name, currentUser!.address, claimAmount);
      this.copyrightsStore.loadCopyrightState(address);
      // this.copyrightsStore.updateCopyrightsOnChange();
    } catch (error) {
      console.error(error);
      this.isClaiming.set(false);
      this.alertService.open('Could not send transaction to claim document', {appearance: 'error'}).subscribe();
    }
  }

  private stopClaimingLoaderOnClaimChange(): void {
    effect(() => {
      if (this.copyright().claim != undefined) {
        this.isClaiming.set(false);
      }
    }, { allowSignalWrites: true });
  }
  
  private updatePriceInUsdOnChange(): void {
    effect(() => {
      const tonPrice = this.tonPrice();
      const costValue = this.costControlValue();
      
      if (tonPrice && costValue) {
        this.priceInUsd.set((Math.round(costValue * tonPrice * 100) / 100).toFixed(2));
      }
    }, { allowSignalWrites: true });
  }
  
  private setDefaultValueAndValidationOfClaimAmount() {
    effect(() => {
      this.amountControl.setValidators([Validators.required, Validators.min(this.claimAmount())]);
      this.amountControl.setValue(this.claimAmount(), { emitEvent: false, onlySelf: true });
    });
  }

}
