import { TuiPrimitiveTextfieldModule, TuiInputModule } from "@taiga-ui/legacy";
import {Component, DestroyRef, OnInit, inject} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import { TuiAlertService, TuiDialogService, TuiError, TuiLabel, TuiLink, TuiButton } from "@taiga-ui/core";
import { TuiFieldErrorPipe, TuiCheckbox } from "@taiga-ui/kit";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {CreateUserDto} from "../../interfaces/user.interface";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {StoreService} from "../../services/store.service";

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,
    TuiButton,
    TuiLabel,
    TuiError,
    TuiFieldErrorPipe,
    TuiInputModule,
    TuiLink,
    TuiPrimitiveTextfieldModule,
      TuiCheckbox
],
  templateUrl: './welcome-page.component.html',
  styleUrl: './welcome-page.component.scss'
})
export class WelcomePageComponent implements OnInit {
  
  form = new FormGroup({
    name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    address: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    agreeWithTerms: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
  });
  
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly destroyRef = inject(DestroyRef);
  private readonly authService = inject(AuthService);
  private readonly alertService = inject(TuiAlertService);
  private readonly storeService = inject(StoreService);
  private readonly dialogs = inject(TuiDialogService);
  
  ngOnInit(): void {
    if (this.triedToOpenCertificate()) this.showYouNeedToLogInFirstDialog();
  }
  
  showYouNeedToLogInFirstDialog(): void {
    this.dialogs.open(
      'To claim the exclusive rights, we need to know basic information about you to protect your rights.<p>We only need <strong>name and address</strong></p>',
      {
        label: 'Share your info to get copyright',
        size: 's',
      },
    ).subscribe();
  }
  
  next(e: Event): void {
    e.preventDefault();
    
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity()
    
    if (this.form.invalid) {
      return;
    }
    
    const createUserDto: CreateUserDto = {
      name: this.form.controls.name.value,
      address: this.form.controls.address.value,
    };
    
    if (this.invitedByReferral()) {
      createUserDto.referral_code = this.route.snapshot.queryParamMap.get('referral')!;
    }

    this.authService.register(createUserDto).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((success) => {
      if (!success) {
        this.alertService.open('Could not save user', { appearance: 'error' }).subscribe();
        return;
      }

      if (this.triedToOpenCertificate()) {
        this.router.navigate(['copyright', this.route.snapshot.queryParamMap.get('copyright')]);
      } else {
        this.router.navigate(['/dashboard', { queryParams: {} }]);
      }
    });
    
  }
  
  private triedToOpenCertificate(): boolean {
    return this.route.snapshot.queryParamMap.has('copyright');
  }
  
  private invitedByReferral(): boolean {
    return this.route.snapshot.queryParamMap.has('referral');
  }

}
