import {CanActivateFn, Router, UrlTree} from '@angular/router';
import {inject} from "@angular/core";

import {AuthService} from "../services/auth.service";
import {filter, map, Observable, take} from "rxjs";

export const userUnregisteredGuard: CanActivateFn = (): Observable<UrlTree | boolean> => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isAuthenticated$.pipe(
    filter(value => value !== undefined),
    take(1),
    map(isAuthenticated => !isAuthenticated ? true : router.createUrlTree(['/home'])),
  );

};
