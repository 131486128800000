import { TuiProgress, TuiButtonLoading } from "@taiga-ui/kit";
import {Component, effect, input, signal} from '@angular/core';
import {TuiAlertService, TuiNotification, TuiButton, TuiLabel, TuiLink} from "@taiga-ui/core";
import {TonConnectService} from "../../../../../../services/ton-connect.service";
import {Copyright} from "../../../../../../interfaces/copyright.interface";
import { Address } from '@ton/core';
import { CopyrightsStore } from '../../../../../../store/copyrights.store';
import { WebSocketService } from '../../../../../../services/web-socket.service';

@Component({
  selector: 'app-author-view-awaiting-review',
  standalone: true,
  imports: [
    TuiButton,
    TuiLabel,
    TuiNotification,
    TuiProgress,
    TuiButtonLoading,
    TuiLink
  ],
  templateUrl: './author-view-awaiting-review.component.html',
  styleUrl: './author-view-awaiting-review.component.scss'
})
export class AuthorViewAwaitingReviewComponent {
  
  copyright = input.required<Copyright>();
  
  readonly isRejecting = signal<boolean>(false);

  constructor(
    private alertService: TuiAlertService,
    private tonConnectService: TonConnectService,
    private copyrightsStore: CopyrightsStore
  ) {
    effect(() => {
      if (this.copyright().claim === undefined) this.isRejecting.set(false);
    }, { allowSignalWrites: true })
  }

  async reject() : Promise<void> {
    this.isRejecting.set(true);

    const address = Address.normalize(this.copyright().address);
    try {
      await this.tonConnectService.cancel(address);
    } catch (error) {
      console.error(error);
      this.isRejecting.set(false);
      this.alertService.open('Could not send transaction to reject', {appearance: 'error'}).subscribe();
    }
  }
}
