import {environment} from "../../../../../../environments/environment";
import { Copyright } from "../../../../../interfaces/copyright.interface";

export function exclusiveRightsShareableUrl(copyright: Copyright): string | null {
  if (!copyright) return null;

  const urlToApp = encodeURIComponent(`${environment.tgBotUrl}?start=copyright=${copyright.address}`);
  const title = encodeURIComponent('A link to claim exclusive rights');
  return `https://t.me/share/url?url=${urlToApp}&text=${title}`;
}
