import { HttpInterceptorFn } from '@angular/common/http';
import {environment} from "../../environments/environment";
import { TelegramService } from '../services/telegram.service';
import { inject } from '@angular/core';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  // const initData = inject(TelegramService).initData;
  // Ilya
  // Alex
  let initData = '';
  // "query_id=AAEo328sAAAAACjfbyzhnzxs&user=%7B%22id%22%3A745529128%2C%22first_name%22%3A%22evgenii%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22wwinau%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1720029506&hash=cf27024ed762e1ef73c8fef5ad51688bf106ea8870bc1e0cb1ea6f778097074d";
  // Eugeniy
  // const initData = "query_id=AAEo328sAAAAACjfbyzhnzxs&user=%7B%22id%22%3A745529128%2C%22first_name%22%3A%22evgenii%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22wwinau%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1720029506&hash=cf27024ed762e1ef73c8fef5ad51688bf106ea8870bc1e0cb1ea6f778097074d";
  
  switch (localStorage.getItem('tester')) {
    case 'ilya':
      initData = "query_id=AAHTLoQEAAAAANMuhAS-DSoW&user=%7B%22id%22%3A75771603%2C%22first_name%22%3A%22Ilya%22%2C%22last_name%22%3A%22Polotsky%22%2C%22username%22%3A%22ipolotsky%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1723048949&hash=de157528848b04ec78de80eac695b57999bf7dc4508e1afc0f86dea14f529310";
      break;
    case 'alex':
      initData = "query_id=AAGbiIsEAAAAAJuIiwSUi5XL&user=%7B%22id%22%3A76253339%2C%22first_name%22%3A%22Alex%22%2C%22last_name%22%3A%22%F0%9F%A6%8A%22%2C%22username%22%3A%22sillybuttalex%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1724598709&hash=e99dfc3aa6ea268221c18a7af1e966b08606e4a1b5542df7237402e8d53c534a";
      break;
    default:
      initData = inject(TelegramService).initData;
  }
  
  const isReceiverBackend = req.url.startsWith(environment.backendApiUrl);
  if (!initData || !isReceiverBackend) return next(req);

  const copy = req.clone({ setHeaders: { Authorization: initData }});

  return next(copy);
};
