import {Component, DestroyRef, inject, OnInit, signal} from '@angular/core';
import { TuiAlertService, TuiNotification, TuiLoader, TuiButton } from "@taiga-ui/core";
import {TonConnectService} from "../../../../services/ton-connect.service";
import {Router} from "@angular/router";
import {NewCopyrightComponentStore} from "../../new-copyright.component.store";
import {createHash} from "../../../../utils/create-hash-from-files.util";
import {environment} from "../../../../../environments/environment";
import {TelegramService} from "../../../../services/telegram.service";
import { db } from "../../../../db";
import { BackendApiService } from '../../../../services/backend-api.service';
import {AuthService} from "../../../../services/auth.service";

@Component({
  standalone: true,
  imports: [
    TuiButton,
    TuiLoader,
    TuiNotification
  ],
  templateUrl: './declare-copyright-screen.component.html',
  styleUrl: './declare-copyright-screen.component.scss'
})
export class DeclareCopyrightScreenComponent implements OnInit {

  walletConnected = signal<boolean>(false);
  loading = signal<boolean>(false);
  priceInUsd = signal<string | undefined>(undefined)

  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private authService = inject(AuthService);
  private alertService = inject(TuiAlertService);
  private newCopyrightStore = inject(NewCopyrightComponentStore);
  private tonConnectService = inject(TonConnectService);
  private telegramService = inject(TelegramService);
  private backendService = inject(BackendApiService)

  constructor() {
    if (!this.newCopyrightStore.getForm().files.length) {
      this.router.navigate(['new-copyright', 'attach-assets']);
    }
  }

  ngOnInit(): void {
    this.walletConnected.set(this.tonConnectService.isWalletConnected());

    const unsubscribe = this.tonConnectService.tonConnectUI.onStatusChange(async (wallet) => {
      if (wallet?.account) {
        this.walletConnected.set(true);
        this.loading.set(false);
      }
    });

    this.destroyRef.onDestroy(() => {
      unsubscribe();
    })

    this.telegramService.showBackButton('/new-copyright/attach-assets');

    this.backendService.getTonPrice().subscribe(p => {
      this.priceInUsd.set((Math.round(0.05 * p * 100) / 100).toFixed(2))
    })
  }

  connectWallet(): void {
    if (!this.tonConnectService.isWalletConnected()) {
      this.tonConnectService.connectWallet();
      return;
    }
  }

  async declareCopyright(): Promise<void> {
    this.loading.set(true);

    const { name, address } = this.authService.user()!;
    const { files, title } = this.newCopyrightStore.getForm();

    let rootHash: string;
    let fileHashes: string[][];
    try {
      [rootHash, fileHashes] = await createHash(files);
    } catch (error) {
      console.error(error);
      this.loading.set(false);
      this.alertService.open('Could not generate files hash', { appearance: 'error' }).subscribe();
      return;
    }

    const data = fileHashes.map(fh => { return { filename: fh[0], hash: `${fh[1]}` } })
    const royaltyWalletAddress = this.authService.user()!.referrer?.wallets?.at(0)?.address;

    let docData = "empty";
    if (data.length != 0) {
      docData = data.map(d => d.hash + ":" + d.filename).join(",");
    }
    try {
      await this.tonConnectService.declareDocuments({
        title,
        description: null,
        rootHash,
        data: docData,
        royaltyWalletAddress,
        authorName: name,
        authorAddress: address,
        tags: null,
        contractAddress: environment.autoproofContractAddress,
      });
      
      await db.copyrights.add({
        title: title,
        description: null,
        categories: null,
        data: docData,
        root_hash: rootHash,
        author_wallet_address: this.tonConnectService.tonConnectUI.wallet!.account.address!,
        royalty_wallet_address: royaltyWalletAddress,
      })

      this.alertService.open('Copyright declared', { appearance: 'success' }).subscribe(()=> {
        this.router.navigate(['/dashboard']);
      });
      this.router.navigate(['/dashboard']);
    } catch (error) {
      console.log(error)
      this.loading.set(false);
      this.alertService.open('Could not send transaction to declare document', { appearance: 'error' }).subscribe();
      return;
    }
  }

}
