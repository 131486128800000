<main>
  @if (bannerIsVisible()) {
    <tui-island size="l" textAlign="center" tuiTheme="dark">
      <div class="tui-island__content">
        <button tuiButtonClose tuiIconButton (click)="closeBanner()">Close</button>

        <figure class="tui-island__figure">
          <img src="autoproof_logo_white.svg" alt="Logo">
        </figure>

        <div>
          <h3 class="tui-island__title">Freelance Platform Coming Soon</h3>
          <p class="tui-island__paragraph">
            Stay tuned for secure work and safe deals. Learn what you can do now.
          </p>
          <button
            appearance="flat"
            size="m"
            tuiButton
            type="button"
            class="tui-island__footer-button"
            (click)="learnMore()"
          >Learn more</button>
        </div>
      </div>
    </tui-island>
  }

  <app-profile-header/>

  <nav>
    <tui-tabs [(activeItemIndex)]="activeTabIndex">
      <button tuiTab routerLink="copyrights">Copyrights</button>
      <button tuiTab routerLink="referrals">Referrals</button>
      <button tuiTab routerLink="settings">Settings</button>
    </tui-tabs>
  </nav>

  <router-outlet></router-outlet>
</main>
