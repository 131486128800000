<main>
  <header>
    <h4 class="tui-text_h2">Let's know each other</h4>
  </header>

  <form [formGroup]="form">
    <div class="tui-row tui-row_sme">
      <div class="tui-col_12">
        <h3 class="tui-form__header tui-form__header_margin-top_none">Add your authorship info</h3>

        <div class="tui-form__row">
          <tui-input formControlName="name">
            Your full name
            <input tuiTextfieldLegacy/>
            <span class="tui-required"></span>
          </tui-input>

          <tui-error
            formControlName="name"
            [error]="[] | tuiFieldError | async"
          ></tui-error>
        </div>

        <div class="tui-form__row">
          <tui-input formControlName="address">
            Your address
            <input tuiTextfieldLegacy placeholder="e.g. 90 Sunrise Blvd, Citrus Heights, CA 99201, USA"/>
            <span class="tui-required"></span>
          </tui-input>

          <tui-error
            formControlName="address"
            [error]="[] | tuiFieldError | async"
          ></tui-error>
        </div>

        <button tuiButton (click)="next($event)">Done</button>

        <div class="tui-form__row">
         <label tuiLabel>
           <input tuiCheckbox type="checkbox" formControlName="agreeWithTerms">
           <span>I agree with the <a href="https://autoproof.dev/terms" tuiLink target="_blank">Terms and Conditions</a> and <a href="https://autoproof.dev/privacy-policy" tuiLink target="blank">Privacy policy</a></span>
          </label>

          <tui-error
            formControlName="agreeWithTerms"
            [error]="[] | tuiFieldError | async"
          ></tui-error>
        </div>
      </div>
    </div>
  </form>

</main>
