import {Component, effect, input, signal} from '@angular/core';
import {TuiAlertService, TuiNotification, TuiButton, TuiLink} from "@taiga-ui/core";
import {
  TuiProgress,
  TuiButtonLoading,
  TuiInputFiles,
  TuiFilesComponent, TuiFile, TuiInputFilesDirective,
} from "@taiga-ui/kit";

import {TonConnectService} from "../../../../../../services/ton-connect.service";
import {FormControl, Validators, ReactiveFormsModule, FormsModule} from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { createHash } from '../../../../../../utils/create-hash-from-files.util';
import { Copyright } from '../../../../../../interfaces/copyright.interface';
import { Address } from '@ton/core';
import { CopyrightsStore } from '../../../../../../store/copyrights.store';
import { WebSocketService } from '../../../../../../services/web-socket.service';
import {TuiItem} from "@taiga-ui/cdk";

@Component({
  selector: 'app-client-view-confirm-review',
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,
    TuiProgress,
    TuiNotification,
    TuiInputFiles,
    TuiFilesComponent,
    TuiFile,
    TuiButton,
    TuiButtonLoading,
    TuiItem,
    TuiInputFilesDirective,
    TuiLink,
  ],
  templateUrl: './client-view-confirm-review.component.html',
  styleUrl: './client-view-confirm-review.component.scss'
})
export class ClientViewConfirmReviewComponent {

  copyright = input.required<Copyright>();
  isConfirming = signal<boolean>(false);
  validated = signal<boolean>(false);

  readonly filesControl = new FormControl<File[]>([], { nonNullable: true, validators: Validators.required });

  constructor(
    private alertService: TuiAlertService,
    private tonConnectService: TonConnectService,
    private copyrightsStore: CopyrightsStore,
    private webSocketService: WebSocketService
  ) {
    effect(() => {
      if (this.copyright().claim?.viewedDocuments === true) this.isConfirming.set(false);
    }, { allowSignalWrites: true })
  }

  async confirmReceival(): Promise<void> {
    this.isConfirming.set(true);
    
    const address = Address.normalize(this.copyright().address);

    try {
      await this.tonConnectService.viewedDocuments(address);
    } catch (error) {
      console.error(error);
      this.isConfirming.set(false);
      this.alertService.open('Could not send transaction to confirm that documents are viewed', {appearance: 'error'}).subscribe();
    }
  }

  removeFile({ name }: File): void {
    this.filesControl.setValue(
      this.filesControl.value.filter((current: File) => current.name !== name) ?? [],
    );
  }

  async validate(): Promise<void> {
    const files = this.filesControl.value;

    let rootHash: string;
    let fileHashes: string[][]
    try {
      [rootHash, fileHashes] = await createHash(files);
    } catch (error) {
      console.error(error);
      this.alertService.open('Could not generate files hash', { appearance: 'error' }).subscribe();
      return;
    }

    if (rootHash !== this.copyright().document.rootHash) {
      this.validated.set(false);
      this.alertService.open('The Merkle tree root hashes mismatch. The materials are wrong!', { appearance: 'error' }).subscribe();
      return;
    } else {
      this.validated.set(true);
      this.alertService.open('These are the same files that were declared!', { appearance: 'success' }).subscribe();
      return;
    }
  }

}
