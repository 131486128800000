import { TuiHeader, TuiCardLarge, TuiCardMedium } from "@taiga-ui/layout";
import { TuiLoader, TuiIcon, TuiTitle, TuiSurface, TuiButton, TuiAppearance } from "@taiga-ui/core";
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {AsyncPipe, NgForOf} from "@angular/common";
import {CopyrightStatusPipe} from "../../../pipes/copyright-status.pipe";
import { TuiBadge, TuiBadgeNotification, TuiSegmented } from "@taiga-ui/kit";
import {CopyrightsStore} from "../../../store/copyrights.store";
import { CategoryClassPipe } from '../../../pipes/category-class.pipe';
import {liveQuery} from "dexie";
import {db, LocalCopyright} from "../../../db";

@Component({
  selector: 'app-copyrights',
  standalone: true,
  imports: [
    RouterLink,
    AsyncPipe,
    TuiButton,
    CategoryClassPipe,
    TuiAppearance,
    TuiBadge,
    TuiCardLarge,
    TuiCardMedium,
    TuiHeader,
    TuiLoader,
    TuiSurface,
    TuiTitle,
    CopyrightStatusPipe,
    TuiSegmented,
    TuiIcon,
    TuiBadgeNotification,
    NgForOf,
  ],
  templateUrl: './copyrights.component.html',
  styleUrl: './copyrights.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyrightsComponent implements OnInit {

  localCopyrights$ = liveQuery<LocalCopyright[]>(() => db.copyrights.toArray());
  trackById = (index: number, item: LocalCopyright): number => item.id || index;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public copyrightsStore: CopyrightsStore,
  ) {
    const id = this.route.snapshot.queryParamMap.get('id') || this.route.snapshot.queryParamMap.get('tgWebAppStartParam');
    if (id) {
      this.router.navigate(['/copyright', id]);
    }
  }
  
  ngOnInit(): void {
    this.copyrightsStore.loadCopyrights();
  }

}
