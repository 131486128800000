import { Pipe, PipeTransform } from '@angular/core';
import {environment} from "../../environments/environment";
import { Address } from '@ton/core';

@Pipe({
  name: 'certificateUrl',
  standalone: true
})
export class CertificateUrlPipe implements PipeTransform {

  transform(certificateId: Address): string {
    return `${environment.backendApiUrl}/certificate/ton/${Address.normalize(certificateId)}`;
  }

}
