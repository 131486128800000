<tui-accordion class="container tui-space_bottom-2">
  <tui-accordion-item [open]="true" [showArrow]="false" [disabled]="true">
    Manage wallets
    <ng-template tuiAccordionItemContent>
      <div class="wallets" *tuiLet="connectedWalletNormalizedAddress$ | async as connectedWalletFriendlyAddress">
        @for (wallet of authService.wallets(); track wallet.address) {
          <tui-chip [tuiHint]="fullWalletAddress">
            {{ wallet | normalizeAddress | cropAddress:6 }}

            @if (connectedWalletFriendlyAddress === wallet.address) {
<!--              <tui-badge appearance="success" tuiStatus/>-->
              <tui-pulse />
            }
          </tui-chip>

          <ng-template #fullWalletAddress>
            <div class="hint">{{ wallet | normalizeAddress }}</div>
          </ng-template>
        }
      </div>

      <tui-notification size="m" appearance="info" class="tui-space_top-2">
        To switch the wallet you need to disconnect the current first.
      </tui-notification>

      <div class="tui-form__buttons tui-space_top-2">
        @if (isWalletConnected$ | async) {
          <button tuiButton appearance="primary" (click)="disconnectWallet()">Disconnect current wallet</button>
        } @else {
          <button tuiButton appearance="primary" (click)="connectWallet()">Connect wallet</button>
        }
      </div>
    </ng-template>
  </tui-accordion-item>
</tui-accordion>
