<header class="tui-space_top-3">
  <section class="profile-wrapper">
    @if (authService.user(); as user) {
      <!-- TODO: Replace with real image -->
      <tui-avatar src="https://i.pinimg.com/564x/35/9b/6a/359b6a8cee5894e22497357efe197b53.jpg"/>

      <div class="name-and-address">
        <div class="name">{{user.name}}</div>
        @if ((wallet$ | async | normalizeAddress | cropAddress); as walletAddress) {
          <tui-badge appearance="info">{{walletAddress}}</tui-badge>
        }
      </div>

      @if (!(wallet$ | async)) {
        <button
          tuiButton
          appearance="primary"
          size="xs"
          style="margin-left: auto"
          (click)="connectWallet()"
        >Connect wallet</button>
      }
    }
  </section>
</header>
