<progress tuiProgressBar [max]="5" [segments]="5" [value]="2"></progress>

<tui-notification size="m" appearance="info" class="tui-space_top-3"><div>
  <p class="tui-space_top-0">The applicant has frozen the funds.</p>
  <p>Now you can show the original materials for review.</p>
  <p class="tui-space_bottom-0">Click "I've sent data to client" when you've done so.</p>
</div></tui-notification>

<div class="tui-form__buttons">
  <button
    tuiButton
    type="button"
    appearance="primary"
    size="m"
    [loading]="isConfirmingSentDocuments()"
    (click)="showAcceptanceDialog(template)"
  >I've sent data to client</button>

  <div class="align-center tui-space_top-5">
    <a tuiLink iconStart="@tui.circle-help" href="https://autoproof.dev/app-help" target="_blank">
      Need help? We're here to assist!
    </a>
  </div>
</div>

<ng-template
  #template
  let-observer
>
  <br>
  <h1>Assignment of Exclusive Rights Agreement</h1>
  <p><b>{{ today | date: 'dd.MM.yyyy' }}</b></p>

  <p>This Assignment of Exclusive Rights Agreement (the "Agreement") is made and entered into on this {{ today | date: 'dd' }} day of {{ today | date: 'MMMM' }}, {{ today | date: 'yyyy' }}, by and between:</p>

  <p><b>Freelancer:</b></p>
  <p>Name: {{ copyright().ownerDetails.name }}</p>
  <p>Address: {{ copyright().ownerDetails.address }}</p>

  <p><b>Client:</b></p>
  <p>Name: {{ copyright().claim!.authorDetails.name }}</p>
  <p>Address: {{ copyright().claim!.authorDetails.address }}</p>

  <h4>Recitals:</h4>

  <p>WHEREAS, Freelancer is the owner of all rights, title, and interest in and to the work described as "{{ copyright().document.title }}" (the "Work");</p>

  <p>WHEREAS, Client desires to acquire the exclusive rights to the Work;</p>

  <p>NOW, THEREFORE, in consideration of the mutual covenants and agreements contained herein, the parties hereto agree as follows:</p>

  <h4>1. Assignment of Rights</h4>

  <p>1.1 Freelancer hereby assigns to Client all exclusive rights, title, and interest in and to the Work, including, but not limited to, the following rights:</p>

  <ul class="tui-list tui-list_large">
    <li class="tui-list__item">The right to reproduce the Work;</li>
    <li class="tui-list__item">The right to distribute copies of the Work;</li>
    <li class="tui-list__item">The right to publicly display and perform the Work;</li>
    <li class="tui-list__item">The right to create derivative works based on the Work;</li>
    <li class="tui-list__item">Any and all other rights now or hereafter existing in the Work under the laws of any jurisdiction worldwide.  </li>
  </ul>

  <h4>2. Consideration</h4>

  <p>2.1 In consideration for the assignment of rights set forth herein, Client agrees to pay Freelancer the amount of {{ copyright().price | prettyCost }} TON, payable upon the execution of this Agreement.</p>

  <h4>3. Representations and Warranties</h4>

  <p>3.1 Freelancer represents and warrants that:</p>

  <ul class="tui-list tui-list_large">
    <li class="tui-list__item">Freelancer is the sole and exclusive owner of all rights, title, and interest in and to the Work;</li>
    <li class="tui-list__item">The Work is original and does not infringe upon the rights of any third party;</li>
    <li class="tui-list__item">Freelancer has full power and authority to enter into this Agreement and to assign the rights to the Work as set forth herein.</li>
  </ul>

  <h4>4. Indemnification</h4>

  <p>4.1 Freelancer agrees to indemnify, defend, and hold harmless Client from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in connection with any breach of the representations and warranties made by Freelancer herein.</p>

  <h4>5. Governing Law</h4>

  <p>This Agreement shall be governed by and construed in accordance with the laws of the United Kingdom of Great Britain and Northern Ireland, without regard to its conflicts of law principles.</p>

  <h4>6. Entire Agreement</h4>

  <p>6.1 This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior and contemporaneous understandings and agreements, whether written or oral, with respect to such subject matter.</p>

  <h4>7. Amendments</h4>

  <p>7.1 This Agreement may be amended or modified only by a written instrument signed by both parties.</p>

  <h4>Counterparts</h4>

  <p>8.1 This Agreement may be executed in counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument.</p>

  <p>IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the day and year first above written.</p>

  <p><b>Freelancer:</b></p>
  <p>Name: {{ copyright().ownerDetails.name }}</p>
  <p>Address: {{ copyright().ownerDetails.address }}</p>
  <p>Wallet address:&nbsp;<span style="line-break: anywhere">{{ copyright().owner | normalizeAddress }}</span></p>

  <p><b>Client:</b></p>
  <p>Name: {{ copyright().claim!.authorDetails.name }}</p>
  <p>Address: {{ copyright().claim!.authorDetails.address }}</p>
  <p>Wallet address:&nbsp;<span style="line-break: anywhere">{{ copyright().claim!.author | normalizeAddress }}</span></p>
  <p>Date: {{ today | date: 'dd.MM.yyyy' }}</p>

  <p><b>Smart contract address:</b>&nbsp;<span style="line-break: anywhere">{{ copyright().address | normalizeAddress }}</span></p>

  <tui-notification size="m" appearance="warning">
    <div>
      <p class="tui-space_top-0">Note! At this moment, all copyrights and exclusive rights belong to you. Once you click "Agree," you will sign the agreement to transfer these rights to the client.</p>
      <p>The client will then receive a notification, freeze the payment amount, and ONLY then receive the original materials. If satisfied, the payment will be transferred to your wallet, and the client will sign the agreement, completing the transfer.</p>
      <p class="tui-space_bottom-0">If the client is not satisfied, they will receive a certificate confirming that the rights remain with you and that they had access to the original materials. This ensures your rights are fully protected.</p>
    </div>
  </tui-notification>

  <div class="tui-form__buttons">
    <div class="tui-form__row">
      <button
        class="full-width"
        size="m"
        tuiButton
        appearance="destructive"
        type="button"
        (click)="observer.complete()"
      >Cancel</button>

      <button
        tuiButton
        type="button"
        appearance="primary"
        class="full-width tui-space_top-3"
        size="m"
        [disabled]="isConfirmingSentDocuments()"
        [loading]="isConfirmingSentDocuments()"
        (click)="sentDataToClient(); observer.complete();"
      >Accept</button>

      <div class="align-center tui-space_top-5">
        <a tuiLink iconStart="@tui.circle-help" href="https://autoproof.dev/app-help" target="_blank">
          Need help? We're here to assist!
        </a>
      </div>
  </div>
</div>
</ng-template>
