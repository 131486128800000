<main>
  <app-manage-wallets/>
  <app-personal-information/>

  @if (watchIsWalletNotConnected$ | async) {
    <div class="tui-form__buttons">
      <button
        tuiButton
        size="l"
        type="button"
        appearance="flat"
        class="tui-form__button"
        (click)="logIn()"
      >Log in to wallet</button>
    </div>
  }
</main>
