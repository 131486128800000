import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyCost',
  standalone: true
})
export class PrettyCostPipe implements PipeTransform {
  private tonInGrams = 1000000000;

  transform(cost?: bigint): number {
    const intpart = Math.floor(+(cost ?? 0n).toString() / this.tonInGrams)
    const rempart = (+(cost ?? 0n).toString() % this.tonInGrams)
    const costAsFloat = intpart + (rempart / this.tonInGrams)
    return costAsFloat;
  }

}
