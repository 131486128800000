import {Injectable} from "@angular/core";

export interface NewCopyrightData {
  files: File[];
  title: string;
}

@Injectable()
export class NewCopyrightComponentStore {

  private data: NewCopyrightData = { files: [], title: '' };
  
  setForm(files: File[], title: string): void {
    this.data = { files, title };
  }
  
  getForm(): NewCopyrightData {
    return this.data;
  }

}
