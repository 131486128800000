import {Component, effect, Inject, signal} from '@angular/core';
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {TonConnectService} from "../../../../../../../services/ton-connect.service";
import {TuiAlertService, TuiButton, TuiDialogContext, TuiLoader, TuiNotification} from "@taiga-ui/core";
import {CopyrightsStore} from "../../../../../../../store/copyrights.store";
import {POLYMORPHEUS_CONTEXT} from "@taiga-ui/polymorpheus";
import {toNano} from "@ton/core";
import {TonAmountPipe} from "../../../../../../../pipes/ton-amount.pipe";
import {TuiAutoFocus} from "@taiga-ui/cdk";
import {TuiButtonLoading} from "@taiga-ui/kit";
import {TuiInputModule, TuiInputNumberModule} from "@taiga-ui/legacy";
import {PrettyCostPipe} from "../../../../../../../pipes/pretty-cost";
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop";
import {BackendApiService} from "../../../../../../../services/backend-api.service";

@Component({
  selector: 'app-update-cost-modal',
  templateUrl: './update-cost-modal.component.html',
  styleUrl: './update-cost-modal.component.scss',
  standalone: true,
  imports: [
    TonAmountPipe,
    TuiAutoFocus,
    TuiButton,
    TuiButtonLoading,
    TuiInputModule,
    TuiLoader,
    TuiNotification,
    TuiInputNumberModule,
    ReactiveFormsModule
  ],
  providers: [PrettyCostPipe],
})
export class UpdateCostModalComponent {
  
  readonly costControl = new FormControl<number>(0.25, [Validators.required, Validators.min(0.25)]);
  readonly isLoading = signal<boolean>(false);
  readonly isSubmitting = signal<boolean>(false);
  readonly tonPrice = signal<number | null>(null);
  readonly priceInUsd = signal<string | undefined>(undefined);
  readonly costControlValue = toSignal(this.costControl.valueChanges);

  constructor(
    private tonConnectService: TonConnectService,
    private alertService: TuiAlertService,
    private prettyCostPipe: PrettyCostPipe,
    private backendService: BackendApiService,
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<bigint, { price: bigint, address: string }>,
  ) {
    const floatPrice = this.prettyCostPipe.transform(this.context.data.price);
    this.costControl.setValue(floatPrice);
    
    this.backendService.getTonPrice().pipe(
      takeUntilDestroyed(),
    ).subscribe(price => this.tonPrice.set(price));
    
    effect(() => {
      const tonPrice = this.tonPrice();
      const costValue = this.costControlValue();
      
      if (tonPrice && costValue) {
        this.priceInUsd.set((Math.round(costValue * tonPrice * 100) / 100).toFixed(2));
      }
    }, { allowSignalWrites: true });
  }

  async updateCost(): Promise<void> {
    if (this.costControl.invalid) {
      this.alertService.open('Invalid price', { appearance: 'error' }).subscribe();
      return;
    }
    
    if (this.costControl.value! < 0.25) {
      this.alertService.open("The price can't be less than 0.25 TON", {appearance: 'error'}).subscribe();
      return
    }
    
    this.isSubmitting.set(true);
    
    const address = this.context.data.address;
    const price = toNano(this.costControl.value!);
    
    try {
      await this.tonConnectService.setPrice(address, price);
    } catch (error) {
      console.error(error);
      this.isSubmitting.set(false);
      this.alertService.open('Could not send transaction to set the price', {appearance: 'error'}).subscribe();
      return;
    }
    
    this.isSubmitting.set(false);
    this.alertService.open('Transaction was sent. Await the update.', { appearance: 'success' }).subscribe();
    this.context.completeWith(toNano(this.costControl.value!));
  }
  
}
