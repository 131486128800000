<div class="card" tuiCardLarge tuiSurface="elevated" tuiAppearance="primary">
  <div tuiHeader>
    <h2 tuiTitle>
      <div class="card-title">
        <tui-line-clamp [content]="copyright().document.title" [lineHeight]="30" [linesLimit]="1"/>

        @if (copyright().status | copyrightStatus) {
          <tui-badge appearance="info" size="xl">{{ copyright().status | copyrightStatus }}</tui-badge>
        }
      </div>

      @if (copyright().address) {
        <span tuiSubtitle>
            <a
              tuiLink
              href="{{ tonviewerUrl }}/{{ copyright().address | normalizeAddress }}"
              target="_blank"
            >Document contract address</a>
          </span>
      }

      <span tuiSubtitle>Author: {{author()}}</span>
      <span tuiSubtitle>Owner: {{owner()}}</span>

      @if (copyright().claim) {
        <span tuiSubtitle>Claimer: {{copyright().claim!.authorDetails.name}}, {{copyright().claim!.authorDetails.address}}</span>
      }

<!--      @if (copyright().categories) {-->
<!--        <span tuiSubtitle>Categories: {{ copyright().categories }} </span>-->
<!--      }-->

      @if (copyright().price) {
        <label tuiLabel class="price-label">
          <div>
            Price: <span [class.loading]="isPriceUpdating()">TON {{ copyright().price | prettyCost }}</span>

            @if (priceInUsd()) {
              (~<span [class.loading]="isPriceUpdating()">${{ priceInUsd() }}</span>)
            }
          </div>

          @if (isPriceUpdating()) {
            <tui-loader size="xs"/>
          } @else if (canEditPrice()) {
            <tui-icon
              icon="@tui.pencil"
              [style.height.px]="14"
              [style.width.px]="14"
              [style.cursor]="'pointer'"
              (click)="showUpdateCostDialog()"
            />
          }
        </label>
      }
    </h2>
  </div>

  <footer>
    <a
      tuiButton
      class="see-certificate-button"
      appearance="secondary"
      size="m"
      target="_blank"
      type="button"
      [href]="copyright().address | certificateUrl"
    >Open certificate</a>

    @if (canGetFundsBack()) {
      <button
        tuiIconButton
        appearance="flat"
        iconStart="@tui.ellipsis-vertical"
        size="m"
        type="button"
        [tuiAppearanceState]="dropdownOpen ? 'hover' : null"
        [tuiDropdown]="otherOptionsContent"
        [(tuiDropdownOpen)]="dropdownOpen"
      ></button>
    }
  </footer>
</div>

<ng-template #otherOptionsContent>
  <tui-data-list tuiDataListDropdownManager>
    <button tuiOption class="tui-option" (click)="showCollectFundsDialog()">
      <tui-icon icon="@tui.rotate-ccw"/> Collect contract funds
    </button>

    @if (canEditPrice()) {
      <button tuiOption class="tui-option" [disabled]="isPriceUpdating()" (click)="showUpdateCostDialog()">
        <tui-icon icon="@tui.pencil"/> Update price
      </button>
    }
  </tui-data-list>
</ng-template>
