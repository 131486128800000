import {Component, DestroyRef, OnInit} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TuiAlertService, TuiButton, TuiError} from "@taiga-ui/core";
import {TuiAccordion, TuiFieldErrorPipe} from "@taiga-ui/kit";
import {TuiInputCopyModule, TuiInputModule} from "@taiga-ui/legacy";
import {AuthService} from "../../../../../services/auth.service";
import {catchError, map, of, tap} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {BackendApiService} from "../../../../../services/backend-api.service";
import {TuiLet} from "@taiga-ui/cdk";

@Component({
  selector: 'app-personal-information',
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,
    TuiAccordion,
    TuiButton,
    TuiError,
    TuiFieldErrorPipe,
    TuiInputCopyModule,
    TuiInputModule,
    TuiLet
  ],
  templateUrl: './personal-information.component.html',
  styleUrl: './personal-information.component.scss'
})
export class PersonalInformationComponent implements OnInit {
  
  readonly form = new FormGroup({
    name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    address: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
  });
  
  constructor(
    private authService: AuthService,
    private backendApiService: BackendApiService,
    private alertService: TuiAlertService,
    private destroyRef: DestroyRef,
  ) {}
  
  ngOnInit(): void {
    this.setDefaultFormValues()
  }
  
  update() {
    if (this.form.invalid) return;

    this.backendApiService.updateUser({
      name: this.form.value.name!,
      address: this.form.value.address!,
    }).pipe(
      tap(() => this.authService.fetchUser()),
      map(({ result }) => result),
      catchError(() => of(false)),
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((success) => {
      if (!success) {
        this.alertService.open('Could not update user', { appearance: 'error' }).subscribe();
      }
    });
  }
  
  private setDefaultFormValues(): void {
    const user = this.authService.user();
    if (!user) return;

    this.form.setValue({ name: user!.name, address: user!.address });
  }
}
