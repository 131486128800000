import {Component} from '@angular/core';
import { TuiButton } from "@taiga-ui/core";
import {TonConnectService} from "../../../services/ton-connect.service";
import {ManageWalletsComponent} from "./components/manage-wallets/manage-wallets.component";
import {PersonalInformationComponent} from "./components/personal-information/personal-information.component";
import {map, startWith, tap} from "rxjs";
import {AsyncPipe} from "@angular/common";

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    AsyncPipe,
    TuiButton,
    ManageWalletsComponent,
    PersonalInformationComponent
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent {

  watchIsWalletNotConnected$ = this.tonConnectService.watchWallet().pipe(
    map(() => !this.tonConnectService.isWalletConnected()),
  );
  
  constructor(private tonConnectService: TonConnectService) {}
  
  logIn(): void {
    this.tonConnectService.connectWallet()
  }

}
