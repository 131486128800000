import {Injectable} from '@angular/core';
import {ConnectedWallet, SendTransactionResponse, TonConnectUI} from "@tonconnect/ui";
import { beginCell } from "@ton/ton";
import {Account, Wallet} from "@tonconnect/sdk";
import { storeClaimApproval, storeSetPrice } from "../contracts/V0/autoproof";
import { storeClaimRequest, storeGetFunds } from "../contracts/V0/document";
import {BehaviorSubject, map, Observable} from "rxjs";
import {
  createDeclareDocumentTransactionUtil,
  ICreateDeclareDocumentTransaction
} from "../utils/create-declare-document-transaction.util";

@Injectable({
  providedIn: 'root'
})
export class TonConnectService {
  
  readonly #statusChanged$ = new BehaviorSubject<ConnectedWallet | null>(null);

  readonly isWalletConnected$ = this.watchConnectionChange().pipe(
    map(() => this.isWalletConnected()),
  );
  
  readonly tonConnectUI = new TonConnectUI({
    manifestUrl: 'https://mini-app.autoproof.dev/tonconnect-manifest.json'
//    actionsConfiguration: {
//      twaReturnUrl: "https://t.me/autoproofbot/app"
//    }
  });
  
  constructor() {
    this.tonConnectUI.onStatusChange(wallet => this.#statusChanged$.next(wallet));
  }

  async waitUntilConnected(): Promise<void> {
    await this.tonConnectUI.connectionRestored;
  }
  
  isWalletConnected(): boolean {
    return this.tonConnectUI.connected;
  }
  
  watchConnectionChange(): Observable<ConnectedWallet | null> {
    return this.#statusChanged$.asObservable();
  }

  get account(): Account | null {
    return this.tonConnectUI.account;
  }

  connectWallet(): void {
    if (!this.isWalletConnected()) {
      this.tonConnectUI.openModal();
      return;
    }
  }

  disconnectWallet(): void {
    this.tonConnectUI.disconnect();
  }
  
  watchWallet(): Observable<Wallet | null> {
    return this.#statusChanged$.asObservable().pipe(
      map(() => this.tonConnectUI.wallet),
    );
  }

  async declareDocuments(declaration: ICreateDeclareDocumentTransaction): Promise<void> {
    this.connectWallet()

    const declareDocumentTransaction = createDeclareDocumentTransactionUtil(declaration);
    await this.tonConnectUI.sendTransaction(declareDocumentTransaction);
    // TODO: we can use the transaction hash later
  }

  async setPrice(documentAddress: string, price: bigint) {
    this.connectWallet()

    const bodyCell = beginCell();
    storeSetPrice({
        $$type: "SetPrice",
        price: price,
    })(bodyCell);

    const sentTime = Math.floor(Date.now() / 1000);

    const transaction = {
      validUntil: sentTime + 60 * 5, // 5 min
      messages: [
        {
          address: documentAddress,
          amount: "15000000",
          payload: bodyCell.endCell().toBoc().toString("base64")
        }
      ]
    };

    await this.tonConnectUI.sendTransaction(transaction);
  }

  async getFunds(documentAddress: string, amount: bigint) {
    this.connectWallet()

    let bodyCell = beginCell();
    storeGetFunds({
        $$type: "GetFunds",
        amount: amount,
    })(bodyCell);

    const sentTime = Math.floor(Date.now() / 1000);

    const transaction = {
      validUntil: sentTime + 60 * 5, // 5 min
      messages: [
        {
          address: documentAddress,
          amount: "10000000",
          payload: bodyCell.endCell().toBoc().toString("base64")
        }
      ]
    };

    await this.tonConnectUI.sendTransaction(transaction);
  }

  async approve(documentAddress: string, assignmentHash: string) {
    this.connectWallet()

    let bodyCell = beginCell();
    storeClaimApproval({
        $$type: "ClaimApproval",
        assignmentHash: assignmentHash,
    })(bodyCell);

    const sentTime = Math.floor(Date.now() / 1000);
    const amount = "60000000"
    const transaction = {
      validUntil: sentTime + 60 * 5, // 5 min
      messages: [
        {
          address: documentAddress,
          amount: amount,
          payload: bodyCell.endCell().toBoc().toString("base64")
        }
      ]
    };

    await this.tonConnectUI.sendTransaction(transaction);
  }

  async claimRightsTransfer(documentAddress: string, name: string, address: string, amount: bigint) {
    this.connectWallet()

    let bodyCell = beginCell();
    storeClaimRequest({
        $$type: "ClaimRequest",
        authorDetails: {
          $$type: "PersonDetails",
          name: name,
          address: address
        },
    })(bodyCell);

    const sentTime = Math.floor(Date.now() / 1000);

    const transaction = {
      validUntil: sentTime + 60 * 5, // 5 min
      messages: [
        {
          address: documentAddress,
          amount: amount.toString(),
          payload: bodyCell.endCell().toBoc().toString("base64")
        }
      ]
    };

    await this.tonConnectUI.sendTransaction(transaction);
  }

  async mkCommentTx(documentAddress: string, comment: string, amount: bigint): Promise<SendTransactionResponse> {
    this.connectWallet()

    const sentTime = Math.floor(Date.now() / 1000);
    const transaction = {
      validUntil: sentTime + 60 * 5, // 5 min
      messages: [
        {
          address: documentAddress,
          amount: amount.toString(),
          payload: beginCell().storeUint(0, 32).storeStringTail(comment).endCell().toBoc().toString("base64")
        }
      ]
    };

    return await this.tonConnectUI.sendTransaction(transaction);
  }

  async sentDocuments(documentAddress: string): Promise<SendTransactionResponse> {
    return this.mkCommentTx(documentAddress, "mark-documents-as-sent", BigInt(50000000));
  }

  async viewedDocuments(documentAddress: string): Promise<SendTransactionResponse> {
    return this.mkCommentTx(documentAddress, "mark-documents-as-viewed", BigInt(50000000));
  }

  async cancel(documentAddress: string): Promise<SendTransactionResponse> {
    return this.mkCommentTx(documentAddress, "reject", BigInt(50000000));
  }
}
