<main>
  <header>
    <h2 class="tui-text_h2">Guard Your Digital Assets</h2>
    <h6 class="tui-text_h6">Get a certificate now</h6>
  </header>

  <tui-notification size="m" appearance="info" class="tui-space_bottom-2">
    We don't access or upload your file.
    The digital fingerprint for the certificate is created on your device.
  </tui-notification>

  <form [formGroup]="form">
    <div class="tui-row tui-row_sme">
      <div class="tui-col_12">
        <div class="tui-form__row">
          <label tuiInputFiles>
            <input tuiInputFiles formControlName="files" [multiple]="true" [maxFileSize]="6e+9"/>
          </label>
        </div>

        <tui-files>
          @for (file of form.controls.files.value; track file.name) {
            <tui-file
              tuiItem
              [file]="file"
              [showDelete]="true"
              (remove)="removeFile(file)"
            ></tui-file>
          }
        </tui-files>

        <div class="tui-form__row">
          <tui-textarea formControlName="title">
            Title
            <span class="tui-required"></span>
          </tui-textarea>

          <tui-error formControlName="title" [error]="[] | tuiFieldError | async"/>
        </div>

        <button tuiButton (click)="next($event)">Next</button>
      </div>
    </div>
  </form>
</main>
