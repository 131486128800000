import { TuiBadge, TuiAvatar, TuiChip } from "@taiga-ui/kit";
import { Component } from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {TonConnectService} from "../../../../services/ton-connect.service";
import {AuthService} from "../../../../services/auth.service";
import {CropAddressPipe} from "../../../../pipes/crop-address.pipe";
import {NormalizeAddressPipe} from "../../../../pipes/normalize-address.pipe";
import {TuiButton} from "@taiga-ui/core";

@Component({
  selector: 'app-profile-header',
  standalone: true,
  imports: [
    AsyncPipe,
    TuiAvatar,
    TuiBadge,
    TuiChip,
    CropAddressPipe,
    NormalizeAddressPipe,
    TuiButton,
  ],
  templateUrl: './profile-header.component.html',
  styleUrl: './profile-header.component.scss'
})
export class ProfileHeaderComponent {
  
  readonly wallet$ = this.tonConnectService.watchWallet();
  
  constructor(
    private tonConnectService: TonConnectService,
    public authService: AuthService,
  ) {}
  
  connectWallet(): void {
    this.tonConnectService.connectWallet();
  }

}
