<tui-loader [showLoader]="isLoading()">
<!--  <p>Test</p>-->
</tui-loader>

<tui-input-number
  tuiAutoFocus
  [formControl]="costControl"
  [disabled]="isSubmitting()"
>Price in TON @if (priceInUsd()) { (~${{priceInUsd()}}) }:</tui-input-number>

<p>
  <button
    tuiButton
    type="submit"
    class="full-width"
    [disabled]="isLoading()"
    [loading]="isSubmitting()"
    (click)="updateCost()"
  >Update cost</button>
</p>
