import { TuiInputNumberModule } from "@taiga-ui/legacy";
import {Component, computed, effect, input, model, signal} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TuiAlertService, TuiNotification, TuiLoader, TuiButton, TuiLink} from "@taiga-ui/core";
import { TuiChip, TuiButtonLoading } from "@taiga-ui/kit";
import {Address, toNano} from "@ton/core";
import {TonConnectService} from "../../../../../../services/ton-connect.service";
import {exclusiveRightsShareableUrl} from "../../utils/exclusive-rights-url.util";
import {Copyright} from "../../../../../../interfaces/copyright.interface";
import { CopyrightsStore } from '../../../../../../store/copyrights.store';
import { BackendApiService } from '../../../../../../services/backend-api.service';
import {AuthService} from "../../../../../../services/auth.service";
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop";

  @Component({
    selector: 'app-author-view-created',
    standalone: true,
    imports: [
      FormsModule,
      ReactiveFormsModule,
      TuiButton,
      TuiChip,
      TuiInputNumberModule,
      TuiNotification,
      TuiLoader,
      TuiButtonLoading,
      TuiLink
    ],
    templateUrl: './author-view-created.component.html',
    styleUrl: './author-view-created.component.scss',
  })
  export class AuthorViewCreatedComponent {

    readonly costControl = new FormControl<number>(0.25, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0.25)],
    });
    
    copyright = input.required<Copyright>();
    isPriceUpdating = model<boolean>(false);

    readonly isAuthor = computed(() =>
      this.authService.walletsAddresses().includes(Address.normalize(this.copyright()?.author))
    );

    readonly costAsFloat = computed(() => {
      const copyrightConst = +(this.copyright()!.price ?? 0n).toString();
      const intpart = Math.floor(copyrightConst / this.tonInGrams);
      const rempart = copyrightConst % this.tonInGrams;

      return intpart + (rempart / this.tonInGrams);
    });
    
    readonly transferRightsUrl = computed(() => exclusiveRightsShareableUrl(this.copyright()));
    
    readonly newCost = signal<bigint | undefined>(undefined);
    // readonly isUpdatingCost = signal<boolean>(false);
    readonly priceInUsd = signal<string | undefined>(undefined);
    readonly tonPrice = signal<number | null>(null);
    readonly costControlValue = toSignal(this.costControl.valueChanges);

    private tonInGrams = 1000000000;

    constructor(
      private authService: AuthService,
      private alertService: TuiAlertService,
      private tonConnectService: TonConnectService,
      private copyrightsStore: CopyrightsStore,
      private backendService: BackendApiService
    ) {
      this.backendService.getTonPrice().pipe(
        takeUntilDestroyed(),
      ).subscribe(price => this.tonPrice.set(price));
      
      effect(() => {
        const tonPrice = this.tonPrice();
        const costValue = this.costControlValue();

        if (tonPrice && costValue) {
          this.priceInUsd.set((Math.round(costValue * tonPrice * 100) / 100).toFixed(2));
        }
      }, { allowSignalWrites: true });

      effect(() => {
        if (this.copyright().price !== undefined) {
          this.costControl.setValue(this.costAsFloat());
        }
      }, { allowSignalWrites: true });
      
      // TODO: Check what this needs for
      effect(() => {
        if (this.copyright() && this.newCost() && this.copyright().price?.toString() === this.newCost()!.toString()) {
          this.isPriceUpdating.set(false);
          this.newCost.set(undefined);
        }
      }, { allowSignalWrites: true })
    }

    transferRights(): void {
      if (this.costControl.invalid || this.isPriceUpdating() || !(this.costAsFloat()!)) {
        return;
      }

      const url = this.transferRightsUrl();
      if (url) window.open(url);
    }

    async updateCost(): Promise<void> {
      if (this.costControl.invalid) {
        this.alertService.open('Invalid price', { appearance: 'error' }).subscribe();
        return;
      }

      if (this.costControl.value < 0.25) {
        this.alertService.open("The price can't be less than 0.25 TON", {appearance: 'error'}).subscribe();
        return
      }

      this.isPriceUpdating.set(true);

      const address = this.copyright().address.toString();
      const price = toNano(this.costControl.value);

      this.newCost.set(price)

      try {
        await this.tonConnectService.setPrice(address, price);
      } catch (error) {
        console.error(error);
        this.isPriceUpdating.set(false);
        this.alertService.open('Could not send transaction to set the price', {appearance: 'error'}).subscribe();
      }
    }
    
  }
