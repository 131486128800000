<main>
  <header>
    <h2 class="tui-text_h2">Declare copyrights</h2>
    <h6 class="tui-text_h6">The authority of the file will belong to your wallet</h6>
  </header>

  @if (loading()) {
    <div class="loading">
      <tui-loader [showLoader]="true"/>
    </div>
  } @else {
    @if (!walletConnected()) {
      <tui-notification size="m" appearance="info" >Connect the wallet first. We won't charge any money and it's completely safe.</tui-notification>
      <button tuiButton class="connect-button" (click)="connectWallet()">Connect wallet</button>
    } @else {
      <tui-notification size="m" appearance="info" ><div>
        <p>Declare the copyright by signing transaction. Pay only internal transaction fee.</p>
        <p>Please note, that <strong>0.05 TON @if (priceInUsd()) { ~ ${{priceInUsd()}}  } @else {}</strong> is used to cover the transaction fee. The rest of the sum will be returned to your wallet.</p>
      </div></tui-notification>
      <button tuiButton class="pay-button" (click)="declareCopyright()">Declare copyright</button>
    }
  }
</main>
