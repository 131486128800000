<progress tuiProgressBar [max]="5" [segments]="5" [value]="4"></progress>

<tui-notification size="m" appearance="info" class="tui-space_top-3"><div>
  <p class="tui-space_top-0">The fact that the client has reviewed the materials has been recorded.</p>
  <p class="tui-space_bottom-0">If they accept the work, funds will be transferred to you, exclusive rights will transfer to the client, and copyright will remain with you.</p>
</div></tui-notification>

<div class="tui-form__buttons">
  <button
    tuiButton
    type="button"
    appearance="destructive"
    size="m"
    [disabled]="isRejecting()"
    [loading]="isRejecting()"
    (click)="reject()"
  >Reject</button>

  <div class="align-center tui-space_top-5">
    <a tuiLink iconStart="@tui.circle-help" href="https://autoproof.dev/app-help" target="_blank">
      Need help? We're here to assist!
    </a>
  </div>
</div>
