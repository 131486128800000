import { TuiTextareaModule } from "@taiga-ui/legacy";
import {CUSTOM_ELEMENTS_SCHEMA, Component, OnInit} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import { TuiNotification, TuiError, TuiButton } from "@taiga-ui/core";
import {
  TuiFieldErrorPipe,
  TuiFile,
  TuiFilesComponent,
  TuiInputFiles,
  TuiInputFilesDirective
} from "@taiga-ui/kit";
import {Router} from "@angular/router";
import {NewCopyrightComponentStore} from "../../new-copyright.component.store";
import {TelegramService} from "../../../../services/telegram.service";
import {TuiItem} from "@taiga-ui/cdk";

@Component({
  selector: 'app-attach-assets-screen',
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    TuiNotification,
    TuiFile,
    TuiFilesComponent,
    TuiTextareaModule,
    TuiError,
    TuiFieldErrorPipe,
    TuiButton,
    TuiInputFilesDirective,
    TuiInputFiles,
    TuiItem,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './attach-assets-screen.component.html',
  styleUrl: './attach-assets-screen.component.scss'
})
export class AttachAssetsScreenComponent implements OnInit {

  readonly form = new FormGroup({
    files: new FormControl<File[]>([], { nonNullable: true, validators: Validators.required }),
    title: new FormControl<string>('',  { nonNullable: true, validators: Validators.required }),
  });

  constructor(
    private router: Router,
    private newCopyrightStore: NewCopyrightComponentStore,
    private telegramService: TelegramService,
  ) {}

  ngOnInit(): void {
    const data = this.newCopyrightStore.getForm();
    if (data.files.length) this.form.controls.files.setValue(data.files);
    if (data.title) this.form.controls.title.setValue(data.title);

    this.telegramService.showBackButton('/dashboard');
  }

  removeFile({ name }: File): void {
    this.form.controls.files.setValue(
      this.form.controls.files.value?.filter((current: File) => current.name !== name) ?? [],
    );
  }

  next(e: Event): void {
    e.preventDefault();

    this.form.markAllAsTouched();
    this.form.controls.title.updateValueAndValidity();
    if (this.form.invalid) return;

    this.newCopyrightStore.setForm(this.form.value.files!, this.form.value.title!);

    this.router.navigate(['new-copyright', 'declare']);
  }

}
