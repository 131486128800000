@if (copyrightsStore.isLoading()) {
  <tui-loader [showLoader]="true"/>
} @else {
  <section class="copyrights">
    <!--  ngFor directive is required to work with Dexie's liveQuery (@for doesn't work for some reason)  -->
    <div *ngFor="let item of localCopyrights$ | async; trackBy:trackById">
      <div
        tuiCardLarge
        tuiSurface="flat"
        tuiAppearance="secondary"
      >
        <div tuiHeader>
          <h2 tuiTitle>
            <div class="card-title">
              <div>{{ item.title }}</div>
            </div>
          </h2>
          <tui-loader [showLoader]="true"/>
        </div>
      </div>
    </div>

    @for (copyright of copyrightsStore.sortedCopyrights(); track copyright.address) {
      <div [routerLink]="['/copyright', copyright.address]">
        <div
          tuiCardLarge
          tuiSurface="elevated"
          tuiAppearance="secondary"
          class="{{copyright.category | categoryClass}}"
          [style.cursor]="'pointer'"
        >
          <div tuiHeader>
            <h2 tuiTitle>
              <div class="card-title">
                <div>{{ copyright.document.title }}</div>
                @if (copyright.status | copyrightStatus) {
                  <tui-badge appearance="info">{{ copyright.status | copyrightStatus }}</tui-badge>
                }
              </div>

              @switch (copyright.status) {
                @case ('money_frozen') {
                  <span tuiSubtitle>Ready to send assets</span>
                }
                @case ('files_sent') {
                  <span tuiSubtitle>The client is reviewing your files</span>
                }
                @case ('viewed_by_client') {
                  <span tuiSubtitle>Waiting the client to approve</span>
                }
              }
            </h2>
          </div>

    <!--    <footer>-->
    <!--      <button-->
    <!--        appearance="secondary"-->
    <!--        size="m"-->
    <!--        tuiButton-->
    <!--        type="button"-->
    <!--      >-->
    <!--        @if (item.status === 'done') {-->
    <!--          See certificate-->
    <!--        } @else if (certificateLoading().includes(snapshot.id)) {-->
    <!--          <tui-loader [showLoader]="true"></tui-loader>-->
    <!--        } @else {-->
    <!--          Complete creation-->
    <!--        }-->
    <!--      </button>-->
    <!--    </footer>-->
        </div>
      </div>
    } @empty {
      <!-- TODO: (Taiga UI migration) tuiAction has been removed in favour of tuiCardLarge + tuiSurface. Change the template according to this example https://taiga-ui.dev/layout/card-large -->
      <button
        tuiCardLarge
        icon="@tui.file-plus"
        tuiSurface="elevated"
        type="button"
        class="action"
        routerLink="/new-copyright"
      >You don't have any copyrights yet.<br/>Let's create the first one!</button>
    }
  </section>
}

<button
  tuiButton
  class="new-copyright"
  routerLink="/new-copyright"
>Protect new Digital Assets</button>
