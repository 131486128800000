import { TuiHeader, TuiCardLarge, TuiCardMedium } from "@taiga-ui/layout";
import { TuiAmountPipe } from "@taiga-ui/addon-commerce";
import { TuiIslandDirective, TuiInputNumberModule } from "@taiga-ui/legacy";
import {
  Component,
  OnInit,
  computed,
  signal, model,
} from '@angular/core';
import {ActivatedRoute, RouterLink, RouterOutlet} from '@angular/router';
import { TuiNotification, TuiDataList, TuiLoader, TuiDropdown, TuiTitle, TuiSurface, TuiLink, TuiButton, TuiAppearance, TuiLabel } from "@taiga-ui/core";
import {CopyrightStatusPipe} from "../../../../pipes/copyright-status.pipe";
import { TuiAppBar } from "@taiga-ui/layout";
import {AsyncPipe} from "@angular/common";
import { TuiLineClamp, TuiBadge, TuiAvatar, TuiTabs, TuiProgress } from "@taiga-ui/kit";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ContractAddressPipe } from '../../../../pipes/contract-address';
import { PrettyCostPipe } from '../../../../pipes/pretty-cost';
import {AuthorViewCreatedComponent} from "./components/author-view-created/author-view-created.component";
import { TelegramService } from '../../../../services/telegram.service';
import {AuthorViewFrozenMoneyComponent} from "./components/author-view-frozen-money/author-view-frozen-money.component";
import {
  AuthorViewAwaitingReceivalComponent
} from "./components/author-view-awaiting-receival/author-view-awaiting-receival.component";
import {
  AuthorViewAwaitingReviewComponent
} from "./components/author-view-awaiting-review/author-view-awaiting-review.component";
import {ClientViewClaimingComponent} from "./components/client-view-claiming/client-view-claiming.component";
import {
  ClientViewAwaitingMaterialsComponent
} from "./components/client-view-awaiting-materials/client-view-awaiting-materials.component";
import {
  ClientViewConfirmReviewComponent
} from "./components/client-view-confirm-review/client-view-confirm-review.component";
import {
  ClientViewConfirmMaterialsComponent
} from "./components/client-view-confirm-materials/client-view-confirm-materials.component";
import {CopyrightCardComponent} from "./components/copyright-card/copyright-card.component";
import {CopyrightsStore} from "../../../../store/copyrights.store";
import { Address } from '@ton/core';
import { Observable } from 'rxjs';
import { WebsocketEvent } from '../../../../interfaces/copyright.interface';
import { WebSocketService } from '../../../../services/web-socket.service';
import {AuthService} from "../../../../services/auth.service";

@Component({
  selector: 'app-copyright',
  standalone: true,
  imports: [
    CopyrightStatusPipe,
    RouterLink,
    ReactiveFormsModule,
    TuiAppearance,
    TuiBadge,
    TuiCardLarge, TuiCardMedium,
    TuiHeader,
    TuiSurface,
    TuiTitle,
    TuiLoader,
    AsyncPipe,
    TuiLabel,
    TuiNotification,
    TuiAmountPipe,
    TuiProgress,
    TuiButton,
    TuiCardLarge, TuiCardMedium,
    TuiButton,
    TuiLink,
    TuiButton,
    RouterLink,
    TuiTabs,
    RouterOutlet,
    TuiAvatar,
    TuiBadge,
    TuiDropdown,
    TuiDataList,
    TuiAppBar,
    ContractAddressPipe,
    PrettyCostPipe,
    TuiInputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    TuiButton,
    AuthorViewCreatedComponent,
    TuiIslandDirective,
    TuiLineClamp,
    AuthorViewFrozenMoneyComponent,
    AuthorViewAwaitingReceivalComponent,
    AuthorViewAwaitingReviewComponent,
    ClientViewClaimingComponent,
    ClientViewAwaitingMaterialsComponent,
    ClientViewConfirmReviewComponent,
    ClientViewConfirmMaterialsComponent,
    CopyrightCardComponent
  ],
  templateUrl: './copyright.component.html',
  styleUrl: './copyright.component.scss',
})
export class CopyrightComponent implements OnInit {

  readonly copyright = computed(() => {
    return this.copyrightsStore.copyrights().find(({ address }) => Address.normalize(address) === this.copyrightId)
  });
  readonly isOwner = computed(() => // false
    this.authService.user()?.wallets.map(iw => iw.address).includes(this.copyright()?.owner.toString() ?? "")
  );

  // TODO: Complete
  readonly showProgress = computed(() => {
    return false;
  });
  
  // TODO: Complete
  readonly progressStep = computed(() => {
    return 1;
  });
  
  readonly copyrightId = this.activatedRoute.snapshot.paramMap.get('id');

  readonly failed = signal<boolean>(false);
  isPriceUpdating = signal<boolean>(false);

  constructor (
    public copyrightsStore: CopyrightsStore,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private telegramService: TelegramService,
    private webSocketService: WebSocketService
  ) {}

  ngOnInit() {
    this.telegramService.showBackButton('/dashboard');

    // this.webSocketService.openConnection() as Observable<WebsocketEvent>

    try {
      const addr = Address.normalize(this.copyrightId ?? "")
      // We already subscribe to wallets changes in app.component.ts
      // this.webSocketService.subscribeToContracts([addr])
      this.webSocketService.getCopyrightByAddress(addr)
    } catch (e) {
      this.failed.set(true)
    }

  }

}
