import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'cropAddress',
  standalone: true,
})
export class CropAddressPipe implements PipeTransform {
  transform(normalizedAddress: string | null | undefined, pad = 3): string | undefined {
    if (!normalizedAddress) return;
    
    return `${normalizedAddress?.slice(0, pad)}...${normalizedAddress?.slice(-pad)}`;
  }
}
