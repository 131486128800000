<!-- Taiga migration TODO: tuiButton "whiteblock-active" appearance is no longer available. Use 'appearance="whiteblock" data-mode="checked"' -->
<progress tuiProgressBar [max]="5" [segments]="5" [value]="3"></progress>

<tui-notification size="m" appearance="info" class="tui-space_top-3"><div>
  <p class="tui-space_top-0">The author has indicated that they have transferred the original materials to you for review.</p>
  <p class="tui-space_bottom-0">Please validate that the files you've received are the same as declared ones and confirm that you have reviewed them.</p>
</div></tui-notification>

<div class="form tui-space_top-1">
  <div class="tui-row tui-row_sme">
    <div class="tui-col_12">
      <div class="tui-form__row">
        <label tuiInputFiles>
          <input tuiInputFiles [formControl]="filesControl" [multiple]="true" [maxFileSize]="6e+9"/>
        </label>
      </div>

      <tui-files>
        @for (file of filesControl.value; track file.name) {
          <tui-file
            tuiItem
            [file]="file"
            [showDelete]="true"
            (remove)="removeFile(file)"
          />
        }
      </tui-files>

      <div class="tui-form__buttons">
        <button
          size="m"
          tuiButton
          type="button"
          (click)="validate()"
        >Validate the materials</button>

        <button
          tuiButton
          type="button"
          size="m"
          class="tui-space_top-3"
          [appearance]="validated() ? 'primary' : 'accent'"
          [disabled]="isConfirming()"
          [loading]="isConfirming()"
          (click)="confirmReceival()"
        >Confirm receival of the materials</button>

        <div class="align-center tui-space_top-5">
          <a tuiLink iconStart="@tui.circle-help" href="https://autoproof.dev/app-help" target="_blank">
            Need help? We're here to assist!
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

