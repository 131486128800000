import { TuiProgress } from "@taiga-ui/kit";
import {Component} from '@angular/core';
import { TuiNotification, TuiLabel } from "@taiga-ui/core";

@Component({
  selector: 'app-client-view-awaiting-materials',
  standalone: true,
  imports: [
    TuiLabel,
    TuiNotification,
    TuiProgress
  ],
  templateUrl: './client-view-awaiting-materials.component.html',
  styleUrl: './client-view-awaiting-materials.component.scss'
})
export class ClientViewAwaitingMaterialsComponent {}
