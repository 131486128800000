import {Copyright, WebsocketEvent} from "../interfaces/copyright.interface";
import {signalStore, withState, withMethods, patchState, withHooks, getState, withComputed} from '@ngrx/signals';
import {computed, effect, forwardRef, inject} from "@angular/core";
import {concatMap, of, Observable} from "rxjs";
import {WebSocketService} from "../services/web-socket.service";
import {db} from "../db";
import { isCopyrightTheSame} from "../utils/compare-copyrights-data.utils";
import {Address} from "@ton/core";
import { AuthService } from "../services/auth.service";

interface IState {
  copyrights: Copyright[],
  isLoading: boolean;
}

const initialState: IState = {
  copyrights: [],
  isLoading: false,
};

export const CopyrightsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withHooks((store) => {
    const webSocketService = inject(forwardRef(() => WebSocketService));
    
    return {
      onInit: () => {
        effect(() => {
          const state = getState(store);
          if (state.copyrights.length) {
            console.log('state.copyrights.length', state.copyrights.length);
            syncLocalCopyrights(state.copyrights);
          }
        });
        
        watchWebsocketResponses(store, webSocketService);
      },
    }
  }),
  withComputed(({ copyrights }) => {
    const authService = inject(AuthService);

    return {
      sortedCopyrights: computed(() => {
        const wallets = authService.walletsAddresses();
        const categorizedCopyrights = copyrights().map((copyright) => {
          // TODO: Add enums
          let category = 0;

          if (wallets.includes(copyright.claim?.author.toString() ?? "")) {
            category = 3;
          } else if (wallets.includes(copyright.author.toString())) {
            category = 1;
          } else if (wallets.includes(copyright.owner.toString())) {
            category = 2;
          }

          return { ...copyright, category };
        });

        return categorizedCopyrights.sort((a, b) => {
          if (a.category! > b.category!) {
            return -1;
          } else if (a.category! < b.category!) {
            return 1;
          }

          return Number(b.initTxLt - a.initTxLt);
        })
      }),
    }
  }),
  withMethods((
    store,
    webSocketService = inject(WebSocketService),
    authService = inject(AuthService),
  ) => ({
    // TODO: If we keep "isLoading: false" in watchWebsocketResponses, the loading can stop by unanticipated event of a different kind
    // switchMap(() => (webSocketService.currentConnection as Observable<WebsocketEvent>).pipe(
    //   filter((websocketEvent: WebsocketEvent) => websocketEvent.event === 'copyrights'),
    //   tap(() => patchState(store, { isLoading: false })),
    // )),
    loadCopyrights: () => {
      patchState(store, { isLoading: true });
      webSocketService.getCopyrightsByWallets(authService.walletsAddresses());
    },
    loadCopyright: (address: string) => {
      webSocketService.getCopyrightByAddress(address);
    },
    loadCopyrightState: (address: string) => {
      webSocketService.getState(address);
    },
    updateCopyrightsOnChange: () => {
      webSocketService.subscribeToWallets(authService.walletsAddresses());
    },
  })),
);

export type CopyrightsStore = InstanceType<typeof CopyrightsStore>

async function syncLocalCopyrights(serverCopyrights: Copyright[]): Promise<void> {
    const localCopyrights = await db.copyrights.toArray();
    console.log('localCopyrights', localCopyrights);

    const duplicateCopyrights = localCopyrights.filter((localCopyright) =>
      serverCopyrights.some(serverCopyright => isCopyrightTheSame(serverCopyright, localCopyright)),
    );

    console.log('duplicateCopyrights', duplicateCopyrights);
    
    if (duplicateCopyrights.length) {
      db.copyrights.bulkDelete(duplicateCopyrights.map(item => item.id!));
    }
}
function watchWebsocketResponses(store: any, webSocketService: WebSocketService): void {
  (webSocketService.currentConnection as Observable<WebsocketEvent>).pipe(
    concatMap((copyrightsEvent) => {
      switch (copyrightsEvent.event) {
        case 'copyrights':
          patchState(store, {isLoading: false, copyrights: copyrightsEvent.data});
          break;
        
        case 'new-state':
          patchState(store, ({copyrights}: any) => {
            const copyrightsCopy = [...copyrights];
            const changedCopyrightIndex = copyrightsCopy.findIndex(copyright =>
              Address.normalize(copyright.address) === Address.normalize(copyrightsEvent.address),
            );
            
            if (changedCopyrightIndex < 0) {
              console.error('Changed copyright was not found in copyrights');
              return {copyrights}
            }
            
            const item = copyrightsCopy[changedCopyrightIndex]
            const newState = copyrightsEvent.data
            const itemDataHasChanged = item.assignmentHash != newState.assignmentHash
              || item.claim != newState.claim
              || item.owner != newState.owner
              || item.price != newState.price
              || item.status != newState.status
              || item.balance != newState.balance
            
            if (!itemDataHasChanged) {
              return {copyrights: copyrights};
            }
            
            copyrightsCopy[changedCopyrightIndex] = {...item, ...newState};
            return {copyrights: copyrightsCopy};
          });
          break;
        
        case 'new-copyright': {
          patchState(store, ({copyrights}: any) => {
            const copyrightsCopy = [...copyrights];
            const changedCopyrightIndex = copyrightsCopy.findIndex(copyright =>
              Address.normalize(copyright.address) === Address.normalize(copyrightsEvent.data.address),
            );
            
            if (changedCopyrightIndex >= 0) {
              return {copyrights};
            }
            
            return { copyrights: [...copyrightsCopy, copyrightsEvent.data as Copyright] };
          });
        }
      }
      
      return of(null);
    }),
  ).subscribe();
}

