<tui-accordion class="container tui-space_bottom-2">
  <tui-accordion-item [open]="true" [showArrow]="false" [disabled]="true">
    Personal information
    <ng-template tuiAccordionItemContent>
      <form [formGroup]="form">
        <div class="tui-form__row">
          <tui-input formControlName="name">
            Your full name
            <input tuiTextfieldLegacy/>
            <span class="tui-required"></span>
          </tui-input>

          <tui-error
            formControlName="name"
            [error]="[] | tuiFieldError | async"
          ></tui-error>
        </div>

        <div class="tui-form__row">
          <tui-input formControlName="address">
            Your address
            <input tuiTextfieldLegacy placeholder="e.g. 90 Sunrise Blvd, Citrus Heights, CA 99201, USA"/>
            <span class="tui-required"></span>
          </tui-input>

          <tui-error
            formControlName="address"
            [error]="[] | tuiFieldError | async"
          ></tui-error>
        </div>

        <div class="tui-form__buttons">
          <button
            size="l"
            tuiButton
            type="submit"
            class="tui-form__button"
            (click)="update()"
          >
            Update
          </button>
        </div>
      </form>
    </ng-template>
  </tui-accordion-item>
</tui-accordion>
