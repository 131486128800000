import {Address, beginCell} from "@ton/ton";
import {storeDeclareDocument} from "../contracts/V0/autoproof";

export interface ICreateDeclareDocumentTransaction {
  title: string;
  description: string | null;
  authorName: string;
  authorAddress: string;
  rootHash: string;
  data: string;
  tags: string | null;
  contractAddress: string;
  royaltyWalletAddress?: string;
}

interface DeclareDocumentTransactionBody {
  validUntil: number;
  messages: {
    address: string;
    amount: string;
    payload: string;
  }[];
}

export function createDeclareDocumentTransactionUtil(data: ICreateDeclareDocumentTransaction): DeclareDocumentTransactionBody {
  let bodyCell = beginCell();

  let parsedAddress: Address | null;
  if (data.royaltyWalletAddress === undefined) {
    parsedAddress = null;
  } else {
    parsedAddress = Address.parse(data.royaltyWalletAddress);
  }

  storeDeclareDocument({
    $$type: "DeclareDocument",
    document: {
      $$type: "DocumentData",
      author: {
        "$$type": "PersonDetails",
        "name": data.authorName,
        "address": data.authorAddress
      },
      title: data.title,
      rootHash: data.rootHash,
      data: data.data,
      tags: data.tags,
      description: data.description
    },
    royaltyWalletAddress: parsedAddress,
  })(bodyCell);

  return {
    validUntil: Math.floor(Date.now() / 1000) + 60 * 5, // 5 sec
    messages: [
      {
        address: data.contractAddress,
        amount: "50000000",
        payload: bodyCell.endCell().toBoc().toString("base64")
      }
    ]
  };
}
