import { Routes } from '@angular/router';
import { WelcomePageComponent } from './pages/welcome/welcome-page.component';
import { userUnregisteredGuard } from './guards/user-unregistered.guard';
import { userRegisteredGuard } from './guards/user-registered.guard';
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {CopyrightsComponent} from "./pages/dashboard/copyrights/copyrights.component";
import {NewCopyrightComponent} from "./pages/new-copyright/new-copyright.component";
import {
  AttachAssetsScreenComponent
} from "./pages/new-copyright/components/attach-assets-screen/attach-assets-screen.component";
import {DeclareCopyrightScreenComponent} from "./pages/new-copyright/components/declare-copyright-screen/declare-copyright-screen.component";
import {CopyrightComponent} from "./pages/dashboard/copyrights/copyright/copyright.component";
import {SettingsComponent} from "./pages/dashboard/settings/settings.component";
import { ReferralsComponent } from './pages/dashboard/referrals/referrals.component';
import {
  userCanOpenCopyrightGuard
} from "./guards/user-can-open-copyright.guard";

export const routes: Routes = [
  { path: 'welcome', component: WelcomePageComponent, canActivate: [userUnregisteredGuard] },
  {
    path: 'dashboard', component: DashboardComponent,
    canActivate: [
      // unregisteredClientOpensCertificateFromLinkGuard,
      userRegisteredGuard,
    ],
    children: [
      { path: 'referrals', component: ReferralsComponent },
      { path: 'copyrights', component: CopyrightsComponent },
      { path: 'settings', component: SettingsComponent },
      { path: '', pathMatch: 'full', redirectTo: 'copyrights' },
    ],
  },
  { path: 'copyright/:id', component: CopyrightComponent, canActivate: [userCanOpenCopyrightGuard] },
  { path: 'new-copyright', component: NewCopyrightComponent, canActivate: [userRegisteredGuard], children: [
    { path: 'attach-assets', component: AttachAssetsScreenComponent },
    { path: 'declare', component: DeclareCopyrightScreenComponent },
    { path: '', pathMatch: 'full', redirectTo: 'attach-assets' },
  ]},
  { path: '**', redirectTo: 'dashboard' },
];

