import {TuiAlertService, TuiRoot} from "@taiga-ui/core";
import {Component, effect} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import { TelegramService } from "./services/telegram.service";
import {TonConnectService} from "./services/ton-connect.service";
import {catchError, EMPTY, filter, map, Observable, switchMap, tap} from "rxjs";
import { Address } from "@ton/core";
import {BackendApiService} from "./services/backend-api.service";
import {AuthService} from "./services/auth.service";
import { environment } from "../environments/environment";
import {WebsocketEvent} from "./interfaces/copyright.interface";
import {WebSocketService} from "./services/web-socket.service";
import {CopyrightsStore} from "./store/copyrights.store";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TuiRoot],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  
  private readonly urlParams = new URLSearchParams(window.location.search);

  constructor(
    private router: Router,
    private authService: AuthService,
    private telegramService: TelegramService,
    private tonConnectService: TonConnectService,
    private backendApiService: BackendApiService,
    private copyrightsStore: CopyrightsStore,
    private alertService: TuiAlertService,
  ) {
    console.log('href', window.location.href);
    console.log('initData', window.Telegram?.WebApp.initData);

    if (environment.analyticsToken) {
      window.telegramAnalytics.init({
        token: environment.analyticsToken,
        appName: 'Autoproof'
      });
    }

    this.telegramService.initialize();
    this.checkIfClientOpensCertificateFromLink();
    this.checkIfUserComesWithReferral();
    this.saveConnectedWalletsToUser();
    
    effect(() => {
      if (this.authService.walletsAddresses()?.length) {
        this.copyrightsStore.updateCopyrightsOnChange();
      }
    });
  }
  
  private checkIfClientOpensCertificateFromLink(): void {
    if (this.urlParams.get('action') === 'open_copyright' && this.urlParams.has('id')) {
      this.router.navigate(['copyright', this.urlParams.get('id')], { queryParams: {} });
    }
  }
  
  private checkIfUserComesWithReferral(): void {
    if (this.urlParams.get('action') === 'open_referral' && this.urlParams.has('code')) {
      this.router.navigate(['welcome'], { queryParams: { referral: this.urlParams.get('code') } });
    }
  }
  
  private saveConnectedWalletsToUser(): void {
    this.tonConnectService.watchConnectionChange().pipe(
      filter(Boolean),
      map(data => data.account?.address),
      filter(Boolean),
      map(rawAddress => Address.normalize(rawAddress)),
      filter(newAddress => !this.authService.walletsAddresses().includes(newAddress)),
      switchMap(newAddress => this.backendApiService.addWalletToUser(newAddress).pipe(
        catchError(() => {
          this.alertService.open('Could not save new wallet', { appearance: 'error' });
          return EMPTY;
        }),
      )),
      tap(() => this.authService.fetchUser()),
    ).subscribe();
  }
}
