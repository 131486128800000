import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  UrlTree
} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../services/auth.service";
import {Observable, filter, map, take} from "rxjs";

export const userCanOpenCopyrightGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  
  return authService.isAuthenticated$.pipe(
    filter(value => value !== undefined),
    take(1),
    map((isAuthenticated) => {
      //debugger;
      if (isAuthenticated) return true;
      
      const copyrightId = route.paramMap.get('id');
      if (copyrightId) {
        return router.createUrlTree(['welcome'], { queryParams: { copyright: copyrightId } });
      }

      return router.createUrlTree(['welcome'])
    }),
  );
};
