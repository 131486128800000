import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class TelegramService {

  private backButtonCallback: (() => unknown) | undefined;
  
  constructor(private router: Router) {}
  
  initialize(): void {
    window.Telegram?.WebApp.ready();
    window.Telegram?.WebApp.expand();
    window.Telegram?.WebApp.enableClosingConfirmation();
  }

  get initData(): string {
    return window?.Telegram?.WebApp?.initData;
  }

  get startParam(): string {
    return window?.Telegram?.WebApp.initDataUnsafe.start_param;
  }

  get user(): WebAppUser {
    return window.Telegram?.WebApp.initDataUnsafe.user;
  }
  
  showBackButton(url?: string): void {
    window.Telegram?.WebApp.BackButton.show();
    
    if (url) {
      this.setBackButtonUrl(url);
    }
  }

  hideBackButton(): void {
    window.Telegram?.WebApp.BackButton.hide();
  }
  
  setBackButtonUrl(url: string): void {
    if (this.backButtonCallback) this.removeBackButtonHandler();

    this.backButtonCallback = () => {
      this.router.navigate([url]);
      this.hideBackButton();
      this.removeBackButtonHandler();
    }

    window.Telegram?.WebApp.BackButton.onClick(this.backButtonCallback);
  }
  
  removeBackButtonHandler(): void {
    window.Telegram?.WebApp.BackButton.offClick(this.backButtonCallback);
  }

}
