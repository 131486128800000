import { TuiProgress } from "@taiga-ui/kit";
import { Component } from '@angular/core';
import { TuiNotification, TuiLabel } from "@taiga-ui/core";

@Component({
  selector: 'app-author-view-awaiting-receival',
  standalone: true,
  imports: [
    TuiLabel,
    TuiNotification,
    TuiProgress
  ],
  templateUrl: './author-view-awaiting-receival.component.html',
  styleUrl: './author-view-awaiting-receival.component.scss'
})
export class AuthorViewAwaitingReceivalComponent {}
