<tui-notification size="m" appearance="info">
  <div>
    @if (isAuthor()) {
      <p class="tui-space_top-0">Copyright is protected in your name.</p>
    }

    <p class="tui-space_top-0">Exclusive rights are protected in your name.</p>
    <p class="tui-space_bottom-0">To transfer the exclusive rights to the client, first set a price for your work, then you'll be able to share a link for your client.</p>
  </div>
</tui-notification>

<div class="tui-space_top-3">
  @if (!copyright().price) {
    <div class="tui-form__row">
      <tui-input-number [formControl]="costControl">Price in TON @if (priceInUsd()) { (~${{priceInUsd()}}) }:</tui-input-number>
    </div>
  }

  <div class="tui-form__buttons">
    @if (!copyright().price) {
      <button
        tuiButton
        type="button"
        appearance="secondary"
        size="m"
        [disabled]="isPriceUpdating() || costControl.invalid"
        [loading]="isPriceUpdating()"
        (click)="updateCost()"
      >Save</button>
    }

    @if (copyright().price) {
      <button
        tuiButton
        type="button"
        appearance="primary"
        class="tui-space_top-3"
        size="m"
        [disabled]="isPriceUpdating()"
        (click)="transferRights()"
      >Transfer exclusive rights</button>
    }

    <div class="align-center tui-space_top-5">
      <a tuiLink iconStart="@tui.circle-help" href="https://autoproof.dev/app-help" target="_blank">
        Need help? We're here to assist!
      </a>
    </div>
  </div>
</div>
