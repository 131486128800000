import { TuiInputModule, TuiInputNumberModule } from "@taiga-ui/legacy";
import { TuiHeader, TuiCardLarge, TuiCardMedium } from "@taiga-ui/layout";
import {TuiAmountPipe, TuiCurrencyPipe} from "@taiga-ui/addon-commerce";
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef, effect,
  Injector,
  input, model,
  signal,
} from '@angular/core';
import {ContractAddressPipe} from "../../../../../../pipes/contract-address";
import {CopyrightStatusPipe} from "../../../../../../pipes/copyright-status.pipe";
import {PrettyCostPipe} from "../../../../../../pipes/pretty-cost";
import {
  TuiDialogService,
  TuiNotification,
  TuiDataList,
  TuiIcon,
  TuiDropdown,
  TuiTitle,
  TuiSurface,
  TuiLink,
  TuiDialog,
  TuiButton,
  TuiAppearance,
  TuiLabel,
  TuiOption, TuiLoader
} from "@taiga-ui/core";
import {TuiLineClamp, TuiDataListDropdownManager, TuiBadge, TuiChip, TuiButtonLoading} from "@taiga-ui/kit";
import {Copyright} from "../../../../../../interfaces/copyright.interface";
import {environment} from "../../../../../../../environments/environment";
import {CertificateUrlPipe} from "../../../../../../pipes/certificate-url.pipe";
import { TuiActiveZone, TuiAutoFocus } from "@taiga-ui/cdk";
import {ReactiveFormsModule} from "@angular/forms";
import {PolymorpheusComponent} from "@taiga-ui/polymorpheus";
import {
  CollectContractFundsModalComponent
} from "./collect-contract-funds-modal/collect-contract-funds-modal.component";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { Address } from '@ton/core';
import { BackendApiService } from '../../../../../../services/backend-api.service';
import {AuthService} from "../../../../../../services/auth.service";
import {UpdateCostModalComponent} from "./update-cost-modal/update-cost-modal.component";
import {filter} from "rxjs";

@Component({
  selector: 'app-copyright-card',
  standalone: true,
  imports: [
    ContractAddressPipe,
    CopyrightStatusPipe,
    PrettyCostPipe,
    TuiAppearance,
    TuiBadge,
    TuiButton,
    TuiCardLarge, TuiCardMedium,
    TuiChip,
    TuiHeader,
    TuiLabel,
    TuiLineClamp,
    TuiLink,
    TuiAmountPipe,
    TuiSurface,
    TuiTitle,
    CertificateUrlPipe,
    TuiDropdown,
    TuiDataList,
    TuiActiveZone,
    TuiDataListDropdownManager,
    TuiIcon,
    TuiDialog,
    ReactiveFormsModule,
    TuiInputModule,
    TuiAutoFocus,
    TuiNotification,
    TuiInputNumberModule, TuiCurrencyPipe, TuiOption, TuiLoader
  ],
  templateUrl: './copyright-card.component.html',
  styleUrl: './copyright-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyrightCardComponent {

  copyright = input.required<Copyright>();
  copyrightAuthorFriendlyAddress = computed<string>(() => Address.normalize(this.copyright().author));
  copyrightOwnerFriendlyAddress = computed<string>(() => Address.normalize(this.copyright().owner));
  isCopyrightStale = computed(() => this.copyright().status === 'stale');
  isOwnerOfCopyright = computed<boolean>(() => this.authService.walletsAddresses().includes(this.copyrightOwnerFriendlyAddress()));
  author = computed<string>(() => {
    if (this.authService.walletsAddresses().includes(this.copyrightAuthorFriendlyAddress())) {
      return 'Me';
    }
    
    return `${this.copyright().document.author.name}, ${this.copyright().document.author.address}`;
  });
  owner = computed<string>(() => {
    if (this.isOwnerOfCopyright()) {
      return 'Me';
    }

    return `${this.copyright().ownerDetails.name}, ${this.copyright().ownerDetails.address}`;
  });

  isOwnerAndStatusIsStale = computed(() => this.isOwnerOfCopyright() && this.isCopyrightStale());
  canGetFundsBack = computed(() => this.isOwnerAndStatusIsStale());
  canEditPrice = computed(() => this.isOwnerAndStatusIsStale() && this.copyright().price);

  priceInUsd = signal<number | undefined>(undefined)
  isPriceUpdating = model<boolean>(false);

  newCost = signal<bigint | null>(null);
  dropdownOpen = false;

  readonly costAsFloat = computed(() => {
    const copyrightConst = +(this.copyright()!.price ?? 0n).toString();
    const intpart = Math.floor(copyrightConst / this.tonInGrams);
    const rempart = copyrightConst % this.tonInGrams;

    return intpart + (rempart / this.tonInGrams);
  });

  private tonInGrams = 1000000000;

  readonly tonviewerUrl = environment.tonviewerUrl;
  
  constructor(
    private readonly dialogs: TuiDialogService,
    private readonly injector: Injector,
    private readonly destroyRef: DestroyRef,
    private readonly authService: AuthService,
    private readonly backendService: BackendApiService
  ) {
    // TODO: Fix this. If the Copyright has no cost, the priceInUsd will be calculated badly and price in USD won't be shown.
    this.backendService.getTonPrice().pipe(
      takeUntilDestroyed(),
    ).subscribe(price => this.priceInUsd.set((Math.round(this.costAsFloat() * price * 100) / 100)));
    
    this.disableCostUpdateLoadingWhenConfirmed();
  }
  
  showCollectFundsDialog(): void {
    this.dropdownOpen = false;
    const collectFundsDialog = this.dialogs.open<boolean>(
      new PolymorpheusComponent(CollectContractFundsModalComponent, this.injector),
      {
        dismissible: true,
        label: 'Collect funds from contract',
        data: { "balance": this.copyright().balance, "address": Address.normalize(this.copyright().address) },
      },
    );

    collectFundsDialog.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }
  
  showUpdateCostDialog(): void {
    this.dropdownOpen = false;

    const collectFundsDialog = this.dialogs.open<bigint>(
      new PolymorpheusComponent(UpdateCostModalComponent, this.injector),
      {
        dismissible: true,
        label: 'Update cost',
        data: { price: this.copyright().price, address: Address.normalize(this.copyright().address) },
      },
    );

    collectFundsDialog.pipe(
      filter(Boolean),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((newCost) => {
      this.isPriceUpdating.set(true);
      this.newCost.set(newCost);
    });
  }
  
  private disableCostUpdateLoadingWhenConfirmed(): void {
    effect(() => {
      if (this.copyright() && this.newCost() && this.copyright().price?.toString() === this.newCost()!.toString()) {
        this.isPriceUpdating.set(false);
        this.newCost.set(null);
      }
    }, { allowSignalWrites: true });
  }
}
