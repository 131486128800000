import { TuiButtonLoading } from "@taiga-ui/kit";
import { TuiInputModule } from "@taiga-ui/legacy";
import { TuiAutoFocus } from "@taiga-ui/cdk";
import {Component, effect, Inject, OnInit, signal} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import { TuiAlertService, TuiDialogContext, TuiNotification, TuiLoader, TuiDialog, TuiButton } from "@taiga-ui/core";
import {POLYMORPHEUS_CONTEXT} from "@taiga-ui/polymorpheus";
import {TonAmountPipe} from "../../../../../../../pipes/ton-amount.pipe";
import {TonConnectService} from "../../../../../../../services/ton-connect.service";
import {fromNano, toNano} from "@ton/core";
import { CopyrightsStore } from '../../../../../../../store/copyrights.store';

@Component({
  selector: 'app-collect-contract-funds-modal',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TuiAutoFocus,
    TuiDialog,
    TuiInputModule,
    TuiNotification,
    TonAmountPipe,
    TuiLoader,
    TuiButton,
      TuiButtonLoading
],
  templateUrl: './collect-contract-funds-modal.component.html',
  styleUrl: './collect-contract-funds-modal.component.scss'
})
export class CollectContractFundsModalComponent implements OnInit {
  
  readonly amountControl = new FormControl(null, [Validators.required, Validators.min(0.01)]);
  readonly contractBalance = signal<bigint>(BigInt(0));
  readonly isLoading = signal<boolean>(false);
  readonly isSubmitting = signal<boolean>(false);
  
  constructor(
    private tonConnectService: TonConnectService,
    private alertService: TuiAlertService,
    private copyrightsStore: CopyrightsStore,
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<boolean, { 'address': string, 'balance': bigint }>,
  ) {
    effect(() => {
      if (this.contractBalance() > 0) {
        this.amountControl.addValidators(Validators.max(Number(fromNano(this.contractBalance()))));
        this.amountControl.updateValueAndValidity();
      }
    }, { allowSignalWrites: true })
  }
  
  ngOnInit(): void {
    this.contractBalance.set(this.context.data.balance);
  }

  collect(): void {
    if (this.amountControl.invalid) {
      this.alertService.open('Invalid amount', { appearance: 'error' }).subscribe();
      return;
    }
    
    const amount = toNano(this.amountControl.value!);
    
    this.isSubmitting.set(true);
    
    try {
      this.tonConnectService.getFunds(this.context.data.address, amount);
    } catch (error) {
      console.error(error);
      this.isSubmitting.set(false);
      this.alertService.open('Could not send transaction to get funds', { appearance: 'error' }).subscribe();
      return;
    }
    
    this.isSubmitting.set(false);
    this.alertService.open('Transaction was sent. Await the funds in your wallet.', { appearance: 'success' }).subscribe();
    this.context.completeWith(true);
  }
  
}
