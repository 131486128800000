import { Pipe, PipeTransform } from '@angular/core';
import {fromNano} from "@ton/core";

@Pipe({
  name: 'tonAmount',
  standalone: true
})
export class TonAmountPipe implements PipeTransform {

  transform(value: bigint): string {
    return fromNano(value);
  }

}
