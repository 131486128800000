import {Pipe, PipeTransform} from "@angular/core";
import {Wallet} from "@tonconnect/sdk";
import {Address} from "@ton/core";

@Pipe({
  name: 'normalizeAddress',
  standalone: true,
})
export class NormalizeAddressPipe implements PipeTransform {
  transform(addressOrWallet: Wallet | { address: string } | string | null | undefined): string | undefined {
    if (!addressOrWallet) {
      return;
    }

    if (typeof addressOrWallet === 'string') {
      return Address.normalize(addressOrWallet);
    }
    
    if ('account' in addressOrWallet) {
      return Address.normalize(addressOrWallet.account.address);
    }
    
    if ('address' in addressOrWallet) {
      return Address.normalize(addressOrWallet.address);
    }
    
    return undefined;
  }
}
