import { Pipe, PipeTransform } from '@angular/core';
import {Copyright} from "../interfaces/copyright.interface";

@Pipe({
  name: 'copyrightStatus',
  standalone: true
})
export class CopyrightStatusPipe implements PipeTransform {

  private readonly labels = new Map<Copyright['status'], string>([
    ['stale', ''],
    ['money_frozen', 'Claimed'],
    ['files_sent', 'Files sent by owner'],
    ['viewed_by_client', 'Viewed by client'],
  ]);

  transform(status: Copyright['status']): string {
    return this.labels.get(status) ?? '';
  }

}
