import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryClass',
  standalone: true
})
export class CategoryClassPipe implements PipeTransform {
  transform(category?: number): string {
    if (category == 3) {
        return 'claimer'
    } else if (category == 2) {
        return 'owner'
    }

    return 'author'
  }

}
