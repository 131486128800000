import {Injectable, computed, signal} from "@angular/core";
import {BehaviorSubject, catchError, map, Observable, of} from "rxjs";
import {BackendApiService} from "./backend-api.service";
import {CreateUserDto, IUser, IWallet} from "../interfaces/user.interface";
import { isEqual } from 'lodash';

@Injectable({ providedIn: 'root' })
export class AuthService {
  
  readonly #_isAuthenticated$ = new BehaviorSubject<boolean | undefined>(undefined);
  
  public readonly isAuthenticated$ = this.#_isAuthenticated$.asObservable();
  
  readonly user = signal<IUser | null>(null);
  readonly wallets = computed<IWallet[]>(() => this.user()?.wallets ?? []);
  readonly walletsAddresses = computed<string[]>(
    () => this.wallets().map(w => w.address),
    { equal: isEqual },
  );
  
  constructor(private backendApiService: BackendApiService) {
    this.fetchUser();
  }

  fetchUser(): void {
    this.backendApiService.getUser().pipe(
      catchError(() => of(null)),
    ).subscribe((user) => {
      this.user.set(user);
      this.#_isAuthenticated$.next(Boolean(user));
    });
  }
  
  register(createUserDto: CreateUserDto): Observable<boolean> {
    return this.backendApiService.createUser(createUserDto).pipe(
      map(({ result, user }) => {
        this.user.set(user);
        this.#_isAuthenticated$.next(Boolean(user));

        return result;
      }),
      catchError(() => of(false)),
    )
  }

}
