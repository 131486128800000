import { TuiProgress, TuiButtonLoading } from "@taiga-ui/kit";
import {Component, CUSTOM_ELEMENTS_SCHEMA, effect, Inject, input, OnDestroy, signal} from '@angular/core';
import {
  TuiAlertService,
  TuiDialogContext,
  TuiDialogService,
  TuiNotification,
  TuiButton,
  TuiLabel,
  TuiLink
} from "@taiga-ui/core";
import {TonConnectService} from "../../../../../../services/ton-connect.service";
import { PolymorpheusContent} from '@taiga-ui/polymorpheus';
import { DatePipe } from '@angular/common';
import {Copyright} from "../../../../../../interfaces/copyright.interface";
import { Address } from '@ton/core';
import { CopyrightsStore } from '../../../../../../store/copyrights.store';
import { WebSocketService } from '../../../../../../services/web-socket.service';
import {PrettyCostPipe} from "../../../../../../pipes/pretty-cost";
import {ContractAddressPipe} from "../../../../../../pipes/contract-address";

@Component({
  selector: 'app-author-view-frozen-money',
  standalone: true,
  imports: [
    TuiButton,
    TuiLabel,
    TuiNotification,
    TuiProgress,
    DatePipe,
    TuiButtonLoading,
    PrettyCostPipe,
    ContractAddressPipe,
    TuiLink
  ],
  templateUrl: './author-view-frozen-money.component.html',
  styleUrl: './author-view-frozen-money.component.scss'
})
export class AuthorViewFrozenMoneyComponent {

  copyright = input.required<Copyright>();
  readonly isConfirmingSentDocuments = signal<boolean>(false);
  readonly today = Date.now();

  constructor(
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    private tonConnectService: TonConnectService,
    private alertService: TuiAlertService,
    private copyrightsStore: CopyrightsStore,
    private webSocketService: WebSocketService
  ) {
    effect(() => {
      if (this.copyright().claim?.sentDocuments === true) this.isConfirmingSentDocuments.set(false);
    }, { allowSignalWrites: true })
  }

  async sentDataToClient(): Promise<void> {
    this.isConfirmingSentDocuments.set(true);
    
    const address = Address.normalize(this.copyright().address);
    try {
      await this.tonConnectService.sentDocuments(address);
    } catch (error) {
      console.error(error);
      this.isConfirmingSentDocuments.set(false);
      this.alertService.open('Could not send transaction to notify that documents are sent', {appearance: 'error'}).subscribe();
    }
  }

  showAcceptanceDialog(content: PolymorpheusContent<TuiDialogContext>) {
    this.dialogs.open(content).subscribe();
  }

}
