import {Injectable} from '@angular/core';
import {IUser} from "../interfaces/user.interface";

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private user: IUser = {
    id: 0,
    name: '',
    address: '',
    score: 0,
    invitation_number: 0,
    referral_code: '',
    referrer: null,
    wallets: [],
  };

  shouldShowSharedCopyright = false;

  getUser(): IUser | null {
    return this.user;
  }

  getUserWallets(): string[] {
    return this.user.wallets.map(w => w.address);
  }

  setUser(user: IUser): void {
    this.user = user;
  }

}
