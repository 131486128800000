import {Injectable} from "@angular/core";
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  
  currentConnection: WebSocketSubject<unknown> | undefined = webSocket(environment.webSocketUrl);
  
  closeConnection(): void {
    this.currentConnection?.complete();
    this.currentConnection = undefined;
  }

  subscribeToWallets(addresses: string[]): void {
    this.currentConnection?.next(
      { addresses, event: "subscribe-wallets" }
    );
  }

  subscribeToContracts(addresses: string[]): void {
    this.currentConnection?.next(
      { addresses, event: "subscribe-contracts" }
    );
  }

  getCopyrightsByWallets(addresses: string[]): void {
    this.currentConnection?.next(
      { addresses, event: "get-copyrights-by-wallets" }
    );
  }

  getCopyrightByAddress(address: string): void {
    this.currentConnection?.next(
      { address, event: "get-copyright" }
    );
  }

  getState(address: string): void {
    this.currentConnection?.next(
      { address, event: "get-state" }
    );
  }
}
