import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {NewCopyrightComponentStore} from "./new-copyright.component.store";

@Component({
  selector: 'app-new-copyright',
  standalone: true,
  imports: [RouterOutlet],
  providers: [NewCopyrightComponentStore],
  templateUrl: './new-copyright.component.html',
  styleUrl: './new-copyright.component.scss'
})
export class NewCopyrightComponent {

}
